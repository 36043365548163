import React, { Component } from "react";
import Table from "./common/table";
import moment from "moment-timezone";

class LockStatusTable extends Component {
  //   constructor() {
  //     super();
  //     const user = authService.getCurrentUser();
  //     //if (user && user.isAdmin) this.columns.push(this.newColumn) //push an admin column to the table
  //   }

  columns = [
    {
      path: "s_gateway_name",
      label: "Gateway Name"
    },
    {
      path: "int_battery",
      label: "Battery"
    },
    {
      path: "int_signal_strength",
      label: "Signal Strength"
    },
    {
      path: "l_last_update_time",
      label: "Last Update Time (local time)",
      content: status => {
        const { selectedLock } = this.props;
        console.log(selectedLock.l_timezone_name);
        const lockTimezone = selectedLock.l_timezone_name;
        const format = "D MMM YYYY h:mm a";
        return moment
          .tz(new Date(status.l_last_update_time), lockTimezone)
          .format(format);
      }
    }
    // {
    //   path: "l_last_online",
    //   label: "Last Online",
    //   content: lock => "12:12:12 23:00am"
    // }
  ];

  render() {
    const { status, onSort, sortColumn } = this.props;
    return (
      <Table
        columns={this.columns}
        data={status}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default LockStatusTable;
