import React, { Component } from "react";
import { Jumbotron, Container, Row, Col, Button } from "reactstrap";
import authService from "../services/authService";
import adminService from "../services/adminService";
import moment from "moment";
import { freeTierLabel, paidTierLabel, version, upgradePlanLink } from "./../config.json";
import AccountFooter from "./common/accountFooter";
import IconHeader from "./common/iconHeader";
import TextFieldModal from "./common/TextFieldModal";
class Account extends Component {
  state = {
    user: {},
    modalIsOpen: false,
    modalText: "",
    isModalLoading: false
  };

  componentDidMount() {
    const user = authService.getCurrentUser();
    this.getUserDetails(user._id);
  }

  async getUserDetails(user_id) {
    const resp = await adminService.getUser(user_id);
    this.setState({ user: resp.data });
  }

  formatPlan(user) {
    const dateExpires = moment(user.account_plan_expiry).format("Do MMM YYYY");
    if (user.free_trial === 1)
      return (
        <div>
          <p>Free Trial (expires: {dateExpires})</p>
          <p>
            <Button color="primary" href={upgradePlanLink} target="_blank">
              Upgrade Plan
            </Button>
          </p>
        </div>
      );
    if (user.account_plan === 1) return `Paid Tier: ${paidTierLabel} (expires: ${dateExpires})`;
    return (
      <div>
        <p>Free Tier: {freeTierLabel}</p>
        <p>
          <Button color="primary" href={upgradePlanLink} target="_blank">
            Upgrade Plan
          </Button>
        </p>
      </div>
    );
  }

  buttonClick = async () => {
    const { modalText, user, type } = this.state;
    this.setState({ isModalLoading: true });
    const { data: newUser } = await adminService.updateUserDetails(user._id, modalText, type);
    this.setState({ isModalLoading: false, modalIsOpen: false, user: newUser });
  };

  buttonClose = () => {
    this.setState({ modalIsOpen: false });
  };

  openModal = type => {
    this.setState({ modalIsOpen: true, type, modalText: "" });
  };

  handleTextUpdate = e => {
    this.setState({ modalText: e.target.value });
  };

  render() {
    const { user } = this.state;
    const style = {
      padding: "20px",
      textAlign: "left"
    };
    const bold = {
      fontWeight: "1000"
    };
    const formatTest = type => {
      let thing;
      if (type === "name") {
        thing = "Profile Name";
      } else if (type === "property_name") {
        thing = "Property Name";
      }
      return {
        title: `Edit ${thing}`,
        message: `Enter a new ${thing} here`,
        placeholder: `Enter a new ${thing} here`
      };
    };
    return (
      <React.Fragment>
        <h1>My Account</h1>
        <TextFieldModal
          isOpen={this.state.modalIsOpen}
          isModalLoading={this.state.isModalLoading}
          buttonClose={this.buttonClose}
          buttonClick={this.buttonClick}
          onUpdate={this.handleTextUpdate}
          input={this.state.modalText}
          {...formatTest(this.state.type)}
        />
        <div>
          <Container fluid>
            <Jumbotron>
              <Row style={style}>
                <Col size="6">
                  <Row>
                    <Col size="2">Name:</Col>
                    <Col style={bold}>
                      {user.name}{" "}
                      <IconHeader iconClass="fa fa-edit" label="Edit Profile Name" tooltipId="profileName" onClick={() => this.openModal("name")} />
                    </Col>
                  </Row>
                </Col>
                <Col size="6">
                  <Row>
                    <Col size="2">Email:</Col>
                    <Col style={bold}>{user.email}</Col>
                  </Row>
                </Col>
              </Row>
              <Row style={style}>
                <Col size="6">
                  <Row>
                    <Col size="2">Property Name:</Col>
                    <Col style={bold}>
                      {user.property_name}{" "}
                      <IconHeader
                        iconClass="fa fa-edit"
                        label="Edit Property Name"
                        tooltipId="propertyName"
                        onClick={() => this.openModal("property_name")}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col size="6">
                  <Row>
                    <Col size="2">Account Plan:</Col>
                    <Col style={bold}>
                      <p>{this.formatPlan(user)}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={style}>
                <Col size="6">
                  <Row>
                    <Col size="2">Password Reset:</Col>
                    <Col>Please reset your password using the Mobile App</Col>
                  </Row>
                </Col>
                <Col size="6">
                  {/* <Row>
                    <Col size="2">Free Plan:</Col>
                    <Col style={bold}>{this.formatPlan()}</Col>
                  </Row> */}
                </Col>
              </Row>
            </Jumbotron>
            <AccountFooter />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Account;
