import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Card, Container, Alert, Col } from "reactstrap";
import integrationService from "../../services/integrationService";
import { upgradePlanLink } from "../../config.json" 
import moment from "moment";
import _ from "lodash";

class LogsMain extends Component {
  state = {};

  componentDidMount() {
    const service = this.props.computedMatch.params.service;
    this.getLogs(service);
  }

  async getLogs(service) {
    const account = await integrationService.getIntegrationAccountLogs();
    this.setState({ account, service });
  }

  renderNoLogsAlert() {
    return (
      <Alert color="warning">
        No logs at this time. Please enable an Integration service. Note: a{" "}
        <a href={upgradePlanLink} target="_blank" rel="noopener noreferrer">
          Paid Plan
        </a>{" "}
        is required to enable an Integration service.
      </Alert>
    );
  }

  renderLogs(service, style) {
    if (!this.state.account) return "No logs found for this account.";
    const logs = this.state.account[service.toLowerCase()].logs;
    const sortedLogs = _.sortBy(logs).reverse();
    return sortedLogs.map(item => (
      <Card style={style} key={item.timestamp}>
        <Row>
          <Col xs="3">{moment(item.timestamp).format("HH:mma DD/MM/YYYY")}:</Col>
          <Col xs="9">
            {item.logArray.map(log => {
              return (
                "• " +
                Object.keys(log).map(key => {
                  return ` ${key}: ${log[key]}`;
                }) +
                " \n\n"
              );
            })}
          </Col>
        </Row>
      </Card>
    ));
  }

  render() {
    const { service, account } = this.state;
    const style = {
      padding: 15,
      textAlign: "left",
      whiteSpace: "pre-line"
    };
    return (
      <React.Fragment>
        <Container>
          <Row>
            <h2 style={style}>
              <Link to="/integrations">Integrations</Link> > Logs > {this.state.service}
            </h2>
          </Row>
          <Row>
            <Container>
              <Card style={style}>
                <p>
                  The following logs are captured because there is an integration service running for this account. This list will only track the last
                  24 logs.
                </p>
                {account ? this.renderLogs(service, style) : this.renderNoLogsAlert()}
                {/* <Card style={style}>Log 1</Card> */}
              </Card>
            </Container>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default LogsMain;
