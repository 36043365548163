import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Alert,
  Spinner,
  Row,
  Col
} from "reactstrap";

class UnlockModal extends Component {
  formatUnlockAck(remoteUnlockAck, remoteUnlockMessage) {
    if (remoteUnlockAck === 5)
      return {
        unlockColor: "primary",
        unlockMessage: "Please wait... Unlocking...",
        spinner: true
      };
    if (remoteUnlockAck === 2)
      return {
        unlockColor: "warning",
        unlockMessage: "Failed. Please try again" + remoteUnlockMessage,
        spinner: false
      };
    if (remoteUnlockAck === 8)
      return {
        unlockColor: "warning",
        unlockMessage: remoteUnlockMessage,
        spinner: false
      };
    if (remoteUnlockAck === 1) {
      return {
        unlockColor: "success",
        unlockMessage: "Success. Door unlocked.",
        spinner: false
      };
    } else {
      return {
        unlockColor: "warning",
        unlockMessage: `Failed. An error occured. ${remoteUnlockAck} - ${remoteUnlockMessage}`,
        spinner: false
      };
    }
  }

  render() {
    const {
      modalUnlock,
      toggleUnlockModal,
      remoteUnlockAck,
      remoteUnlockMessage
    } = this.props;

    const { unlockColor, unlockMessage, spinner } = this.formatUnlockAck(
      remoteUnlockAck,
      remoteUnlockMessage
    );
    return (
      <Modal isOpen={modalUnlock}>
        <Form>
          <ModalHeader>Remote Unlock</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Alert color={unlockColor}>
                <Row>
                  <Col>{unlockMessage}</Col>
                  <Col>
                    {spinner ? (
                      <Spinner size="sm" type="grow" color="secondary" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </Alert>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={event => toggleUnlockModal(event)}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default UnlockModal;
