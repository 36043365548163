import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import PinTable from "./pincodeTable";
import lockService from "../services/lockService";
import LoadingTableSpinner from "./common/loadingTableSpinner";
import { passwordLabel } from "../config.json";
import NewPasswordModal from "./pincodeNewModal";
import Moment from "moment";
import InstructionResultModal from "./common/instructionResultModal";
import _ from "lodash";

class Pincode extends Component {
  state = {
    passwords: [],
    modalNewPassword: false,
    serverFlag: 0,
    options: [],
    pincodeNameInputValue: "",
    pincodeInputValue: "",
    startDate: new Date(),
    endDate: Moment(new Date())
      .add(1, "day")
      .toDate(),
    pincodeIndex: 101,
    instructionAck: 5,
    instructionModal: false,
    instructionMessage: "",
    emailChecked: false,
    emailMessage: "",
    emailInput: "",
    newPinModal: false,
    sortColumn: { path: "password_id", order: "desc" }
  };

  componentDidMount() {
    const factoryName = this.props.computedMatch.params.factoryName;
    this.loadPasswordData(factoryName);
  }

  async loadPasswordData(factoryName) {
    const resp = await lockService.getLockPasswords(factoryName);
    const lockTitle = resp.data.l_lock_title;
    this.setState({
      passwords: resp.data.l_passwords,
      factoryName,
      lockTitle,
      serverFlag: 1
    });
    this.formatOptions();
  }

  async formatOptions() {
    const options = this.generateArray(1899);
    const { passwords } = this.state;
    passwords.map(pwd => {
      _.pull(options, pwd.password_id);
    });
    this.setState({ options: this.generateArray(1899), pincodeIndex: options[0] });
  }

  handleDelete = async pwd => {
    const factoryName = this.state.factoryName;
    const password = pwd.password;
    this.setState({ instructionModal: true, instructionAck: 5 });
    const resp = await lockService.deleteLockPassword(factoryName, password);
    this.setState({ instructionAck: resp.data.ack });
    this.loadPasswordData(factoryName);
  };

  handleSubmitForm = async () => {
    const {
      factoryName,
      startDate,
      endDate,
      pincodeNameInputValue,
      pincodeInputValue,
      pincodeIndex,
      emailChecked,
      emailInput,
      emailMessage
    } = this.state;
    const startString = Moment(new Date(startDate)).format("YYYYMMDDHHmm");
    const endString = Moment(new Date(endDate)).format("YYYYMMDDHHmm");

    this.setState({
      newPinModal: true,
      modalNewPassword: false,
      instructionAck: 5
    });
    const resp = await lockService.newLockPassword(
      factoryName,
      startString,
      endString,
      pincodeIndex,
      pincodeInputValue,
      pincodeNameInputValue,
      emailChecked,
      emailInput,
      emailMessage
    );
    this.setState({
      instructionAck: resp.data.ack,
      instructionMessage: resp.data.message
    });

    if (resp.data.l_passwords === undefined) return this.setState({ modalNewPassword: false });
    this.setState({
      modalNewPassword: false,
      passwords: resp.data.l_passwords
    });
    this.formatOptions();
  };

  checkAlreadyExists = (random, passwords) => {
    const object = _.find(passwords, { password: random });
    return Boolean(object);
  };

  handleGenerateRandomPassword = () => {
    let isExisting = true;
    let pincode = "";
    while (isExisting) {
      const x = 1000,
        y = 9000;
      const generateNum = length => {
        return (
          Math.pow(10, length)
            .toString()
            .slice(length - 1) + Math.floor(Math.random() * Math.pow(10, length) + 1).toString()
        ).slice(-length);
      };
      const random = generateNum(4).toString();
      isExisting = this.checkAlreadyExists(random, this.state.passwords);
      pincode = random;
    }
    this.setState({ pincodeInputValue: pincode, pincodeGenerateCount: pincode });
  };

  generateArray = n => [...Array(n)].map((_, index) => index + 101);

  onNewPasswordClick = () => {
    this.setState({ modalNewPassword: true });
  };

  handleStartChange = date => {
    this.setState({
      startDate: date
    });
  };

  handleEndChange = date => {
    this.setState({
      endDate: date
    });
  };

  handleCloseModal = () => {
    this.setState({ modalNewPassword: false });
  };

  handlePincodeNameChange = event => {
    this.setState({
      pincodeNameInputValue: event.target.value
    });
  };

  handlePincodeChange = event => {
    this.setState({
      pincodeInputValue: event.target.value
    });
  };

  handleOptionsChange = event => {
    this.setState({
      pincodeIndex: parseInt(event.target.value)
    });
  };

  toggleInstructionModal = () => {
    this.setState(prevState => ({
      instructionModal: false,
      newPinModal: false
    }));
  };

  handleMessageChange = event => {
    this.setState({
      emailMessage: event.target.value
    });
  };

  handleEmailChange = event => {
    this.setState({
      emailInput: event.target.value
    });
  };

  handleCheckChange = () => {
    this.setState(prevState => ({
      emailChecked: !prevState.emailChecked
    }));
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  formatPage() {
    if (this.state.serverFlag === 0) return <LoadingTableSpinner />;
    const count = this.state.passwords.length;
    if (count === 0) return `No ${passwordLabel}s for this lock. Click New ${passwordLabel} above to create one.`;
    const { sortColumn } = this.state;
    const sortedPasswords = this.filterAndSort(this.state.passwords);

    return <PinTable passwords={sortedPasswords} onDelete={this.handleDelete} sortColumn={sortColumn} onSort={this.handleSort} />;
  }

  filterAndSort(passwords) {
    const { sortColumn } = this.state;
    const sortedAndFiltered = _.orderBy(passwords, [sortColumn.path], [sortColumn.order]);
    return sortedAndFiltered;
  }

  render() {
    const style = {
      padding: 20
    };
    const { lockTitle, factoryName, options, instructionModal, instructionAck, newPinModal, instructionMessage, ...rest } = this.state;

    return (
      <React.Fragment>
        <Container>
          <InstructionResultModal
            instructionModal={instructionModal}
            toggleInstructionModal={this.toggleInstructionModal}
            ack={instructionAck}
            title="Delete"
            functionMessage="Deleting Password"
            successMessage="Password Delete Successfully"
          />
          <InstructionResultModal
            instructionModal={newPinModal}
            toggleInstructionModal={this.toggleInstructionModal}
            ack={instructionAck}
            instructionMessage={instructionMessage}
            title="Add Password"
            functionMessage="Adding Password"
            successMessage="Password Added Successfully"
          />
          <NewPasswordModal
            lockTitle={lockTitle}
            factoryName={factoryName}
            closeModal={this.handleCloseModal}
            options={options}
            onPincodeChange={this.handlePincodeChange}
            onPincodeNameChange={this.handlePincodeNameChange}
            handleEndChange={this.handleEndChange}
            handleStartChange={this.handleStartChange}
            onOptionsChange={this.handleOptionsChange}
            onSubmit={this.handleSubmitForm}
            onCheckChange={this.handleCheckChange}
            onMessageChange={this.handleMessageChange}
            onEmailChange={this.handleEmailChange}
            generateRandomPassword={this.handleGenerateRandomPassword}
            {...rest}
          />
          <Row>
            <h2>
              <Link to="/locks">Locks</Link> > {passwordLabel}s: {lockTitle} - {factoryName}
            </h2>
          </Row>
          <Row style={style}>
            <Button onClick={() => this.onNewPasswordClick()} className="btn btn-success">
              New {passwordLabel}
            </Button>
            <br />
          </Row>
          <Row>{this.formatPage()}</Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Pincode;
