import React, { Component } from "react";
import { CardBody, Card, Alert, Button } from "reactstrap";
import authService from "../../services/authService";
import adminService from "../../services/adminService";
import AccountFooter from "../common/accountFooter";

import UserPlanAlert from "../integration/common/UserPlanAlert";

class ApiKeyStatic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      code: 'TOKEN LOADING...',
    };
  }

  componentDidMount() {
    const user = authService.getCurrentUser();
    this.getUserDetails(user._id);
    this.getStaticApiKey();
  }

  async getUserDetails(user_id) {
    const resp = await adminService.getUser(user_id);

    this.setState({ ...this.state, user: resp.data });
  }

  getStaticApiKey = async () => {
    const key = await adminService.getApiKey();

    this.setState({ ...this.state, code: key });
  }

  handleNewApiKey = async () => {
    this.setState({ ...this.state, code: 'RECYCLING...' });

    await adminService.refreshApiKey();
    const key = await adminService.getApiKey();

    this.setState({ ...this.state, code: key });
  }

  render() {
    const { user, code } = this.state;
    return (
      <React.Fragment>
        <h1>Static API Key</h1>
        <br />
        {user.account_plan === 0 ? <UserPlanAlert user={user} color="info" title="API Keys" /> : null}
        <br />
        <Card>
          <CardBody>
            <h3>Static API Key</h3>
            <p>Use this Static API Key to conduct API requests where you do not have to update the API Key over time. Never share this Static API Key publicly with anyone.</p>
            <p>This API Key may recycle each time you refresh the page because it is generating a fresh API Key. The API Key is definitely static and will not expire if you provide it somewhere.</p>
            <p>Please click the button below to invalidate and recycle the static api key if it is misplaced.</p>
            <Alert color="dark">
              <pre style={{ color: 'white', whiteSpace: 'pre-wrap' }}>{code}</pre>
            </Alert>
            <br/>
            <br/>
            <Button onClick={this.handleNewApiKey}>Recycle Static API Key</Button>
          </CardBody>
        </Card>
        <hr />
        <AccountFooter />
      </React.Fragment>
    );
  }
}

export default ApiKeyStatic;
