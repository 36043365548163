import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner
} from "reactstrap";
import GatewayStatusTable from "./gatewayStatusTable";
import _ from "lodash";
import moment from "moment";

class GatewayModal extends Component {
  state = {
    showGraph: false
  };

  formatBody(gatewayStatus) {
    const { statusFlag, onSort, sortColumn } = this.props;
    if (!statusFlag) return <Spinner type="grow" color="primary" />;
    if (gatewayStatus.a_locks.length === 0)
      return "No activated Lock(s) are nearby this gateway.";

    //const gFactoryName = this.props.selectedGateway.g_factory_name
    gatewayStatus.a_locks.map(lock => {
      lock._id = lock.s_lock_name; //to supply a unique key for each row.
    });

    const status = this.filterGatewayStatus(gatewayStatus.a_locks);

    return (
      <div>
        <GatewayStatusTable
          status={status}
          sortColumn={sortColumn}
          onSort={onSort}
        />
        {this.formatBodyData(gatewayStatus)}
        <Button onClick={() => this.toggleGraph()}>
          View More
        </Button>
        <br></br>
      </div>

    );
  }

  formatBodyData(gatewayStatus) {
    if (!gatewayStatus.a_online_status) return null;
    let version = "";
    let wifi = "";
    let time = "";
    !gatewayStatus.a_online_status[0].int_version
      ? (version = "No firmware version available.")
      : (version = gatewayStatus.a_online_status[0].int_version);
    !gatewayStatus.a_online_status[0].int_wifi_signal
      ? (wifi = "No Wifi signal available.")
      : (wifi = gatewayStatus.a_online_status[0].int_wifi_signal);
    !gatewayStatus.a_online_status[0].l_login_time
      ? (time = "No latest report time available.")
      : (time = gatewayStatus.a_online_status[0].l_login_time);

    var latestReportTime = moment(time).format("D MMM YYYY h:mm a");

    return (
      <div>
        <p>Latest Gateway Firmware: {version}</p>
        <p>Latest WIFI Signal: {wifi}</p>
        <p>Latest Online Report Time: {latestReportTime}</p>
      </div>
    );
  }

  toggleGraph() {
    this.setState({ showGraph: true })
  }

  formatOnlineOfflineGraph(gatewayStatus) {
    if (!gatewayStatus.a_online_status) return null;
    const array = gatewayStatus.a_online_status;
    return (
      <div>
        <br/>
        <h5>Gateway History: </h5>
        <p>This history is based on online and offline report events to the lock server.
          Online Report means a connection was established (green light). 
          Offline Report means the connection was lost (red light).
          Note: this data only represents an approximate connection history for this gateway. It should be considered as an approximate representation of connection events.
        </p>
        <ul>
          {array.map((item, index) => {
            return (
              <li key={index}>
                {item.l_login_time ? `Online Report: ${moment(item.l_login_time).format('h:mm A DD/MM/YYYY')}` : "" }
                {`  :  `}
                {item.l_logout_time ? `Offline Report: ${moment(item.l_logout_time).format('h:mm A DD/MM/YYYY')}` : "" }
                {item.int_version ? `  :  Firmware Version: ${item.int_version}` : "" }
                {item.int_wifi_signal ? `  :  WIFI Signal: ${item.int_wifi_signal}` : "" }
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  filterGatewayStatus(gatewayStatus) {
    const { sortColumn } = this.props;

    const sortedAndFiltered = _.orderBy(
      gatewayStatus,
      [sortColumn.path],
      [sortColumn.order]
    );

    return sortedAndFiltered;
  }

  render() {
    const {
      statusModal,
      closeStatusModal,
      selectedGateway,
      gatewayStatus
    } = this.props;
    return (
      <Modal isOpen={statusModal} size="lg" className="">
        <ModalHeader>
          Gateway Status: {selectedGateway.g_gateway_title} -{" "}
          {selectedGateway.g_factory_name}
        </ModalHeader>
        <ModalBody>
          {this.formatBody(gatewayStatus)}
          {this.state.showGraph && this.formatOnlineOfflineGraph(gatewayStatus)}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={event => closeStatusModal(event)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default GatewayModal;
