import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class InfoModal extends Component {
    state = {}
    render() {
        const { infoModal, onClose, title, body, imgSrc, buttonText } = this.props
        return (
            <Modal isOpen={infoModal} className="">
                <ModalHeader>{title}</ModalHeader>
                <ModalBody>
                    {body}
                </ModalBody>
                <ModalBody>
                    <img src={imgSrc} alt="" />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={onClose}>{buttonText}</Button>
                </ModalFooter>
            </Modal>);
    }
}

export default InfoModal;