import React from "react";
import { Jumbotron, Container, Row, Col, Button } from "reactstrap";
import moment from "moment";
import {
  freeTierLabel,
  paidTierLabel,
  version,
  upgradePlanLink
} from "../../config.json";
import AccountFooter from "../common/accountFooter";

const AccountPlan = () => {
  return (
    <React.Fragment>
      <h1>My Plans</h1>
      <div>
        <Container fluid>
          <Jumbotron>Thanks</Jumbotron>
          <AccountFooter />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AccountPlan;
