import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import LocksTable from "./locksTable";
import lockService from "../services/lockService";
import UpdateNameModal from "./updateNameModal";
import UnlockModal from "./unlockModal";
import StatusModal from "./statusModal";
import { toast } from "react-toastify";
import InstructionResultModal from "./common/instructionResultModal";
import LoadingTableSpinner from "./common/loadingTableSpinner";
import { passwordLabel, keyTypes } from "../config.json";
import _ from "lodash";
import SearchBox from "./searchBox";
import authService from "../services/authService";
import adminService from "../services/adminService";
import moment from "moment";
import AlertX from "./common/Alert";
import { AppContext } from "../App";
import ReactHtmlParser from 'react-html-parser'; 

class Locks extends Component {
  state = {
    locks: [],
    modalName: false,
    modalUnlock: false,
    selectedLock: {},
    updateNameInputValue: "",
    remoteUnlockAck: 5,
    remoteUnlockMessage: "",
    noLocksFlag: 0,
    statusModal: false,
    instructionModal: false,
    instructionAck: 5,
    instructionMessage: "",
    statusFlag: 0,
    searchQuery: "",
    sortColumn: { path: "l_lock_title", order: "asc" },
    statusSortColumn: { path: "l_last_update_time", order: "desc" },
    account_plan: 0,
    buttonDisabled: true,
    tooltipDisabled: false,
    timezoneSelectedValue: "",
    selectedLockTimeZone: "",
    timezoneNames: [],
    warningSign: true,
    consoleStatusMessage: "",
  };
  intervalFunctionID = 0;

  async loadLockData() {
    const lockData = await lockService.getLocks();
    const allKeys = this.transformLockData(lockData);
    if (allKeys.length === 0) this.setState({ noLocksFlag: 1 });
    //this.setState({ noLocksFlag: 1 });
    this.setState({ locks: allKeys });
  }

  async componentDidMount() {
    this.loadLockData();
    this.determineAccountPlan();
    this.loadTimezoneData();
    await this.getConsoleMessage();
  }


  async getConsoleMessage() {
    const consoleMessage = await adminService.getConsoleMessage();
    if(consoleMessage) {
      this.setState({ consoleStatusMessage: consoleMessage.content });
    }
  }

  transformLockData(lockData) {
    const apiData = lockData;
    apiData.ADMIN_KEYS.map(key => {
      key.keyType = keyTypes.admin;
    });
    apiData.PERMANENT_KEYS.map(key => {
      key.keyType = keyTypes.manager;
    });
    const allKeys = apiData.ADMIN_KEYS.concat(apiData.PERMANENT_KEYS);
    return allKeys;
  }

  handleLike = lock => {
    // const movies = [...this.state.movies];
    // const index = movies.indexOf(movie);
    // movies[index] = { ...movies[index] };
    // movies[index].liked = !movies[index].liked;
  };

  handleRFIDClick = lock => {
    const { state } = this.props.location;
    window.location = state ? state.from.pathname : `/locks/rfid/${lock.l_factory_name}`;
  };

  handlePinClick = lock => {
    const { state } = this.props.location;
    window.location = state ? state.from.pathname : `/locks/pincode/${lock.l_factory_name}`;
  };

  handleAuditClick = lock => {
    const { state } = this.props.location;
    window.location = state ? state.from.pathname : `/locks/audit/${lock.l_factory_name}`;
  };

  handleStatusClick = async lock => {
    this.setState({ statusModal: true, selectedLock: lock });

    const resp = await lockService.getLockStatus(lock.l_factory_name);

    this.setState({
      lockStatus: resp.data,
      statusFlag: 1
    });

    this.intervalFunctionID = setInterval(async e => await this.startStatusLoop(lock.l_factory_name), 30000);
  };

  handleStatusModalClose = async lock => {
    clearInterval(this.intervalFunctionID);

    this.setState(prevState => ({
      statusModal: !prevState.statusModal,
      statusFlag: 0,
      intervalFn: {}
    }));
  };

  handleUnlockClick = async lock => {
    this.setState({ modalUnlock: true, remoteUnlockAck: 5 });
    const resp = await lockService.remoteUnlock(lock.l_factory_name);
    this.setState({
      remoteUnlockAck: resp.data.ack,
      remoteUnlockMessage: resp.data.message
    });
  };

  handleUpdateName = async lock => {
    const { selectedLock, updateNameInputValue } = this.state;
    await lockService.updateName(selectedLock.l_factory_name, updateNameInputValue);

    this.loadLockData();

    this.setState(prevState => ({
      modalName: !prevState.modalName,
      selectedLock: {}
    }));
  };

  handleTimezoneChange = async event => {
    const { selectedLock } = this.state;

    //update Name to Server
    const resp = await lockService.updateLockTimezone(selectedLock.l_factory_name, event.target.value);

    this.setState({ selectedLockTimeZone: resp.data.l_timezone_name });
  };

  handleClockSet = async lock => {
    this.setState({ instructionModal: true, instructionAck: 5 });
    const resp = await lockService.setClock(lock.l_factory_name);
    this.setState({
      instructionAck: resp.data.ack,
      instructionMessage: resp.data.message
    });
  };

  updateInputValue = event => {
    this.setState({
      updateNameInputValue: event.target.value
    });
  };

  toggleModal = lock => {
    this.setState(prevState => ({
      modalName: !prevState.modalName,
      selectedLock: lock,
      selectedLockTimeZone: ""
    }));
  };

  toggleUnlockModal = () => {
    this.setState(prevState => ({
      modalUnlock: !prevState.modalUnlock
    }));
  };

  async startStatusLoop(factoryName) {
    this.setState({ statusFlag: 0 });
    const resp = await lockService.getLockStatus(factoryName);

    this.setState({ lockStatus: resp.data, statusFlag: 1 });
  }

  // toggleStatusModal = lock => {
  //   this.setState(prevState => ({
  //     statusModal: !prevState.statusModal,
  //     selectedLock: lock
  //   }));
  // };

  handleSearch = query => {
    this.setState({ searchQuery: query });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleStatusSort = statusSortColumn => {
    this.setState({ statusSortColumn });
  };

  toggleInstructionModal = () => {
    this.setState(prevState => ({
      instructionModal: !prevState.instructionModal
    }));
  };

  getLockTimezone = async factoryName => {
    //const factoryName = this.state.selectedLock.l_factory_name;

    const resp = await lockService.getLockTimezone(factoryName);

    this.setState({ selectedLockTimeZone: resp.data.l_timezone_name });
  };

  async loadTimezoneData() {
    const resp = await lockService.getTimezoneNames();
    this.setState({ timezoneNames: resp.data });
  }

  async determineAccountPlan() {
    const user = authService.getCurrentUser();
    //if (user.account_plan === 1) this.setState({ account_plan: 1 });
    const resp = await adminService.getUser(user._id);
    if (resp.data.account_plan === 1) this.setState({ buttonDisabled: false, tooltipDisabled: true });
    if (resp.data.free_trial === 1) {
      const dateExpires = moment(resp.data.account_plan_expiry).format("DD/MM/YYYY");
      toast.info(`You are currently on a 1 Month Free Trial plan (expires: ${dateExpires})`, {
        autoClose: false
      });
    }
  }

  filterAndSort() {
    const { searchQuery, locks, sortColumn } = this.state;

    let filtered = locks;
    if (searchQuery) filtered = locks.filter(u => (u.l_lock_title ? u.l_lock_title.toLowerCase().includes(searchQuery.toLowerCase()) : u));

    const sortedAndFiltered = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    return sortedAndFiltered;
  }

  render() {
    let count = this.state.locks.length;
    const {
      locks,
      selectedLock,
      modalName,
      modalUnlock,
      remoteUnlockAck,
      remoteUnlockMessage,
      noLocksFlag,
      statusModal,
      instructionAck,
      instructionModal,
      instructionMessage,
      lockStatus,
      statusFlag,
      sortColumn,
      searchQuery,
      statusSortColumn,
      buttonDisabled,
      tooltipDisabled,
      timezoneSelectedValue,
      selectedLockTimeZone,
      timezoneNames
    } = this.state;

    if (noLocksFlag === 1) {
      //message = "No activated locks for this account.";
    }
    let filteredLocks = this.filterAndSort(locks);
    return (
      <React.Fragment>
        <AppContext.Consumer>
          {appContext => {
            return Boolean(this.state.consoleStatusMessage) && appContext.show_console_message && <div className="btn-secondary rounded text-left" style={{ opacity: 0.8 }}>
              <button 
                type="button" 
                className="close position-relative p-3 text-white" 
                aria-label="Close" 
                style={{ right: "0" }}
                onClick={() => appContext.set_show_console_message(!appContext.show_console_message)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="p-5">
            <p style={{ whiteSpace: 'pre-wrap' }}>
             { ReactHtmlParser (this.state.consoleStatusMessage) }
            </p>
              </div>
            </div>
          }}
        </AppContext.Consumer>
        
        
        <Container>
          <InstructionResultModal
            instructionMessage={instructionMessage}
            instructionModal={instructionModal}
            toggleInstructionModal={this.toggleInstructionModal}
            ack={instructionAck}
            title="Set Clock"
            functionMessage="Setting Door Clock"
            successMessage="Clock Set Successfully"
          />
          <StatusModal
            statusModal={statusModal}
            closeStatusModal={this.handleStatusModalClose}
            //toggleStatusModal={this.toggleStatusModal}
            selectedLock={selectedLock}
            lockStatus={lockStatus}
            statusFlag={statusFlag}
            sortColumn={statusSortColumn}
            onSort={this.handleStatusSort}
          />
          <UnlockModal
            modalUnlock={modalUnlock}
            remoteUnlockAck={remoteUnlockAck}
            remoteUnlockMessage={remoteUnlockMessage}
            toggleUnlockModal={this.toggleUnlockModal}
          />
          <UpdateNameModal
            modalName={modalName}
            selectedLock={selectedLock}
            updateNameInputValue={this.updateNameInputValue}
            updateInputValue={this.updateInputValue}
            toggleModal={this.toggleModal}
            onUpdateName={this.handleUpdateName}
            onTimezoneChange={this.handleTimezoneChange}
            getLockTimezone={this.getLockTimezone}
            selectedLockTimeZone={selectedLockTimeZone}
            timezoneNames={timezoneNames}
          />
          <Row>
            <h2>Locks</h2>
          </Row>
          <Row>
            <SearchBox value={searchQuery} onChange={this.handleSearch} placeholder="Search for a Lock Title..." />
          </Row>
          <Row>
            {count !== 0 ? (
              <LocksTable
                locks={filteredLocks}
                onRFID={this.handleRFIDClick}
                onPin={this.handlePinClick}
                onUnlock={this.handleUnlockClick}
                onAudit={this.handleAuditClick}
                onUpdate={this.toggleModal}
                onStatusCheck={this.handleStatusClick}
                onClockSet={this.handleClockSet}
                sortColumn={sortColumn}
                onSort={this.handleSort}
                buttonDisabled={buttonDisabled}
                tooltipDisabled={tooltipDisabled}
              />
            ) : noLocksFlag ? (
              <p>This account has no locks activated or assigned.</p>
            ) : (
              <LoadingTableSpinner />
            )}
          </Row>
          {/* <Row>
            <Col>
              <AlertX canDismiss={true} />
            </Col>
          </Row> */}
        </Container>
      </React.Fragment>
    );
  }
}

export default Locks;
