import React from "react";
import { FormGroup, Label, Input, Col, Alert } from "reactstrap";

const LoginInput = ({ name, label, error, type, placeholder, ...rest }) => {
  return (
    <FormGroup row>
      <Label for={name} sm={2}>
        {label}
      </Label>
      <Col>
        <Input
          {...rest}
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
        />
        {error && <Alert color="warning">{error}</Alert>}
      </Col>
    </FormGroup>
  );
};

export default LoginInput;
