import React, { Component } from "react";
import { Button } from "reactstrap";
import Table from "./common/table";
import Moment from "moment";
import { passwordLabel } from "../config.json";

class PinTable extends Component {
  columns = [
    {
      path: "password_id",
      label: "ID#"
    },
    {
      path: "password_name",
      label: `Name/Remark`
    },
    {
      path: "password",
      label: `${passwordLabel}`
    },
    {
      path: "password_start_time",
      label: "Start Time",
      content: password => {
        return this.formatDate(password.password_start_time);
      }
    },
    {
      path: "password_end_time",
      label: "End Time",
      content: password => {
        return this.formatDate(password.password_end_time);
      }
    },
    {
      path: "password_email",
      label: "Target Email"
    },
    {
      path: "password_message",
      label: "Message"
    }
  ];

  deletePassword = {
    key: "deletePassword",
    label: `Delete ${passwordLabel}`,
    content: password => (
      <Button
        onClick={() => this.props.onDelete(password)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </Button>
    )
  };

  formatDate(dateString) {
    const date = Moment(dateString, "YYYYMMDDHHmm").toDate();
    Moment.locale("en");
    return Moment(new Date(date)).format("D MMM YYYY h:mm a");
  }
  constructor() {
    super();
    this.columns.push(this.deletePassword);
  }

  render() {
    const { passwords, onSort, sortColumn } = this.props;
    if (passwords.length === 0) return "No pincodes";
    return (
      <Table
        columns={this.columns}
        data={passwords}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default PinTable;
