import React, { Component } from "react";
import { Button } from "reactstrap";
import Breadcrumb from "./common/breadcrumb";
import { Container, Row, Col, Spinner } from "reactstrap";
import GatewaysTable from "./gatewaysTable";
import gatewayService from "../services/gatewayService";
import LoadingTableSpinner from "./common/loadingTableSpinner";
import GatewayModal from "./gatewayModal";
import { toast } from "react-toastify";
import _ from "lodash";

class Gateways extends Component {
  state = {
    gateways: [],
    selectedGateway: {},
    statusModal: false,
    statusFlag: 0,
    noGWFlag: 0,
    gatewayStatus: {},
    sortColumn: { path: "l_latest_scan_time", order: "desc" },
    statusSortColumn: { path: "s_lock_name", order: "asc" }
  };

  componentDidMount() {
    this.loadGatewayData();
  }

  async loadGatewayData() {
    const gwData = await gatewayService.getGateways();
    if (gwData.length === 0) this.setState({ noGWFlag: 1 });
    this.setState({ gateways: gwData });
  }

  handleStatusClick = async gateway => {
    this.setState({ statusModal: true, selectedGateway: gateway });
    const data = await gatewayService.getGatewayStatus(gateway.g_factory_name);

    if (data.ack !== 0) {
      toast.warn("Error fetching gateway status data. No data exists for this gateway.");
      this.setState({ statusModal: false });
      return;
    }
    this.setState({ gatewayStatus: data.info, statusFlag: 1 });
  };

  handleStatusModalClose = async () => {
    this.setState(prevState => ({
      statusModal: !prevState.statusModal,
      statusFlag: 0
    }));
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleStatusSort = statusSortColumn => {
    this.setState({ statusSortColumn });
  };

  filterGateways() {
    const { gateways, sortColumn } = this.state;
    const sortedAndFiltered = _.orderBy(gateways, [sortColumn.path], [sortColumn.order]);
    return sortedAndFiltered;
  }

  render() {
    const { gateways, sortColumn, statusSortColumn, noGWFlag, ...rest } = this.state;
    const count = this.state.gateways.length;
    const filteredGateways = this.filterGateways(gateways);
    return (
      <React.Fragment>
        <Container>
          <GatewayModal sortColumn={statusSortColumn} onSort={this.handleStatusSort} {...rest} closeStatusModal={this.handleStatusModalClose} />
          <Row>
            <h2>Gateways</h2>
          </Row>
          <Row>
            {count !== 0 ? (
              <GatewaysTable gateways={filteredGateways} onStatusClick={this.handleStatusClick} sortColumn={sortColumn} onSort={this.handleSort} />
            ) : noGWFlag ? (
              <p>This account has no gateways activated or assigned.</p>
            ) : (
              <LoadingTableSpinner />
            )}
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Gateways;
