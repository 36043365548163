import React, { Component } from "react";
import { passwordLabel } from "../config.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CommonForm from "./common/commonForm";
import { AvForm, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Row, Col, Tooltip } from "reactstrap";
import OnOffSwitch from "./integration/common/switch";

class NewPasswordModal extends CommonForm {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      toolTip: false
    };
  }

  toggle() {
    this.setState({ toolTip: !this.state.toolTip });
  }

  render() {
    const {
      modalNewPassword,
      factoryName,
      lockTitle,
      pincodeNameInputValue,
      onPincodeNameChange,
      pincodeInputValue,
      onPincodeChange,
      closeModal,
      onSubmit,
      options,
      handleStartChange,
      handleEndChange,
      startDate,
      endDate,
      pincodeIndex,
      onOptionsChange,
      emailChecked,
      onCheckChange,
      onEmailChange,
      onMessageChange,
      emailInput,
      emailMessage,
      generateRandomPassword
    } = this.props;
    return (
      <Modal isOpen={modalNewPassword} size="lg">
        <AvForm onValidSubmit={event => onSubmit(event)}>
          <ModalHeader>
            New {passwordLabel} - Lock Name: {lockTitle} - Factory Name: {factoryName}
          </ModalHeader>
          <ModalBody>
            <AvGroup>
              <Row>
                <Col xs="2">
                  <Label for="lockTitle">Name or Remark</Label>
                </Col>
                <Col>
                  <AvInput
                    type="text"
                    name="passwordTitle"
                    id="passwordTitle"
                    value={pincodeNameInputValue}
                    onChange={event => onPincodeNameChange(event)}
                    placeholder="Enter a name or remark"
                    required
                  />
                  <AvFeedback>Please enter a password name or remark</AvFeedback>
                </Col>
              </Row>
            </AvGroup>
            <AvGroup>
              <Row>
                <Col xs="2">
                  <Label for="lockTitle">{passwordLabel} (4 - 6 digits)</Label>
                </Col>
                <Col xs="8">
                  <AvInput
                    type="number"
                    name="password"
                    id="password"
                    value={pincodeInputValue}
                    onChange={event => onPincodeChange(event)}
                    placeholder={`Enter a ${passwordLabel}`}
                    required
                  />
                  <AvFeedback>Please enter a password</AvFeedback>
                </Col>
                <Col xs="2">
                  <Button type="button" id="pinButton" size="sm" className="btn-info" onClick={generateRandomPassword}>
                    Generate Random
                  </Button>
                  <Tooltip placement="top" isOpen={this.state.toolTip} autohide={true} target="pinButton" toggle={this.toggle}>
                    Generate random 4 digit pin code which is not already entered for this lock.
                  </Tooltip>
                </Col>
              </Row>
            </AvGroup>
            <FormGroup>
              <Row>
                <Col xs="2">
                  <Label for="startTime">Start Time </Label>
                </Col>
                <Col>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeCaption="Start Time"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xs="2">
                  <Label for="startTime">End Time</Label>
                </Col>
                <Col>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeCaption="End Time"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xs="2">
                  <Label for="password_id">Index No: #</Label>
                </Col>
                <Col>
                  <Input
                    type="select"
                    name="password_id"
                    className="form-control"
                    id="password_id"
                    onChange={event => onOptionsChange(event)}
                    value={pincodeIndex}
                  >
                    {options.map(option => (
                      <option key={option}>{option}</option>
                    ))}
                  </Input>
                  Note:
                  <small id="emailHelp" className="form-text text-muted">
                    NEO GEN2 BATTERY DOOR LOCK: This number should be between 101 and 200. If you enter a higher number the request will fail.
                  </small>
                  <small id="emailHelp" className="form-text text-muted">
                    NEO CLOUD ACCESS CONTROL READER: This number should be between 101 and 1999
                  </small>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup check>
              <OnOffSwitch id="pincodeSwitch" checked={emailChecked} label={`Send ${passwordLabel} to an email`} onChange={onCheckChange} />
            </FormGroup>
            <br />
            <AvGroup>
              <Row>
                <Col xs="2">
                  <Label for="startTime">Email:</Label>
                </Col>
                <Col>
                  <AvInput
                    type="email"
                    id="email"
                    name="email"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    required={emailChecked}
                    value={emailInput}
                    onChange={event => onEmailChange(event)}
                  />
                  <AvFeedback>Enter a valid email!</AvFeedback>
                  <small id="emailHelp" className="form-text text-muted">
                    We can email the {passwordLabel} on your behalf.
                  </small>
                </Col>
              </Row>
            </AvGroup>
            <FormGroup>
              <Row>
                <Col xs="2">
                  <Label for="startTime">Email message text:</Label>
                </Col>
                <Col>
                  <Input
                    type="textarea"
                    id="message"
                    rows="3"
                    placeholder="Enter a personal message to send to target user"
                    value={emailMessage}
                    onChange={event => onMessageChange(event)}
                  />
                </Col>
              </Row>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <FormGroup>
              <Button color="secondary" onClick={event => closeModal(event)}>
                Cancel
              </Button>{" "}
              <Button color="primary">Submit</Button>
            </FormGroup>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }
}

export default NewPasswordModal;
