import React from "react";
import config from "../../../config.json";
import { Alert } from "reactstrap";

const IntegrationAlert = ({ user, color, title }) => {
  const [visible, setVisible] = React.useState(true);
  const url = config.upgradePlanLink;
  return (
    <Alert color={color || "secondary"} isOpen={visible}>
      Hi {user.name || ""}, it seems you do not have the paid plan. {title || "Integrations"} are only enabled on paid plans.{" "}
      <a href={url}>Start Here</a>
    </Alert>
  );
};

export default IntegrationAlert;
