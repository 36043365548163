import React, { Component } from "react";
import Table from "./common/table";
import Moment from "moment";

class GatewayStatusTable extends Component {
  //   constructor() {
  //     super();
  //     const user = authService.getCurrentUser();
  //     //if (user && user.isAdmin) this.columns.push(this.newColumn) //push an admin column to the table
  //   }

  columns = [
    {
      path: "s_lock_name",
      label: "Lock Factory Name",
      key: "factoryName"
    },
    {
      path: "int_signal_strength",
      label: "Lock Signal Strength",
      key: "signalStrength"
    },
    {
      path: "l_signal_time",
      label: "Last Lock Report (System time)",
      key: "lastReport",
      content: status => {
        Moment.locale("en");
        const format = "D MMM YYYY h:mm a";
        return Moment(new Date(status.l_signal_time)).format(format);
      }
    }
  ];

  render() {
    const { status, onSort, sortColumn } = this.props;
    return (
      <Table
        columns={this.columns}
        data={status}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default GatewayStatusTable;
