import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  Button,
  Input,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import authService from "../../services/authService";
import adminService from "../../services/adminService";
import moment from "moment";
import {
  freeTierLabel,
  paidTierLabel,
  upgradePlanLink
} from "./../../config.json";
import AccountFooter from "../common/accountFooter";
import IntegrationSwitch from "./common/integrationSwitch";

import IntegrationAlert from "./common/integrationAlert";
import UserPlanAlert from "./common/UserPlanAlert";
// import NewbookPage from "./product/NewbookPage";
import CloudbedsAccordionItem from "./product/CloudbedsAccordionItem";
import MewsPage from "./product/MewsPage";

class IntegrationMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      service: "cloudbeds",
      integrationModal: {
        isOpen: false,
        message: "nothing"
      }
    };
  }

  componentDidMount() {
    const user = authService.getCurrentUser();
    this.getUserDetails(user._id);
  }

  async getUserDetails(user_id) {
    const resp = await adminService.getUser(user_id);

    this.setState({ user: resp.data });
  }

  selectService(name) {
    this.setState({ service: name });
  }

  handleToggleModal() {
    this.setState(prevState => ({
      integrationModal: {
        isOpen: !prevState.integrationModal.isOpen
      }
    }));
  }

  formatActiveService(service) {
    if (this.state.service === service) {
      return true;
    }
    return false;
  }

  formatTab(service) {
    const style = {
      padding: "20px",
      textAlign: "left"
    };
    switch (service) {
      case "cloudbeds":
        return <CloudbedsAccordionItem style={style} user={this.state.user} />;
      case "mews":
        return <MewsPage style={style} user={this.state.user} />;
      default:
        return <p>Nothing selected.</p>;
    }
  }

  render() {
    const { user, service } = this.state;
    return (
      <React.Fragment>
        <h1>My Integrations</h1>
        <br />
        {user.account_plan === 0 ? <UserPlanAlert user={user} /> : null}
        <IntegrationAlert />
        <br />
        <div>
          <Row>
            <Col xs={3}>
              <ListGroup>
                <ListGroupItem
                  active={this.formatActiveService("cloudbeds")}
                  onClick={() => this.selectService("cloudbeds")}
                  tag="button"
                  action
                >
                  Cloudbeds
                </ListGroupItem>
                {/* <ListGroupItem
                  active={this.formatActiveService("mews")}
                  onClick={() => this.selectService("mews")}
                  tag="button"
                  action
                >
                  Mews Systems
                </ListGroupItem> */}
              </ListGroup>
            </Col>
            <Col>{this.formatTab(service)}</Col>
          </Row>
          <hr />
          <AccountFooter />
        </div>
      </React.Fragment>
    );
  }
}

export default IntegrationMain;
