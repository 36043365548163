import React, { Component } from "react";
import { Container, Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import adminService from "../../services/adminService";
import AdminLogin from "./adminLogin";
import { KAS_LOGIN_USERNAME, KAS_LOGIN_PASSWORD } from "./constants";
import LoadingTableSpinner from "../common/loadingTableSpinner";
import AdminTable from "./adminTable";
import SearchBox from "../searchBox";
import _ from "lodash";
import DeleteModal from "./deleteModal";
import Moment from "moment";
import authService from "../../services/authService";

import { Fallback } from "./Fallback"

class SuperAdmin extends Component {
  state = {
    isLoggedIn: true,
    usernameValue: "",
    passwordValue: "",
    users: [],
    noUsersFlag: 0,
    searchQuery: "",
    sortColumn: { path: "email", order: "asc" },
    deleteModal: false,
    consoleStatusMessage: "",
    show_api_logs: false,
    api_logs: [],
    api_log: null
  };

  async componentDidMount() {
    await this.getUsers();
    await this.getConsoleMessage();
    await this.getAPILogs();
  }

  async getUsers() {
    const users = await adminService.getUsers();
    // const allKeys = this.transformLockData(lockData);
    if (users.length === 0) return this.setState({ noUsersFlag: 1 });

    this.setState({ users });
  }

  async getConsoleMessage() {
    const consoleMessage = await adminService.getConsoleMessage();
    if(consoleMessage) {
      this.setState({ consoleStatusMessage: consoleMessage.content });
    }
  }

  async getAPILogs() {
    const api_logs_query = await adminService.getAPILogs();
    this.setState({ api_logs: api_logs_query });
  }

  updateUserPlan = async (user_id, account_plan, account_plan_expiry, free_trial) => {
    const accountPlanExpiry = account_plan_expiry || Moment().add(1, 'month').toDate();
    const resp = await adminService.updateUser(
      user_id,
      account_plan,
      accountPlanExpiry,
      free_trial,
    );
    const dateExpiry = Moment(resp.data.account_plan_expiry).format(
      "DD MMM YYYY"
    );
    if (resp.status === 200) {
      if (resp.data.account_plan === 0)
        toast.warn(`Update Plan successful. ${resp.data.name}'s plan is: OFF`);
      if (resp.data.account_plan === 1)
        toast.success(
          `Update Plan successful. ${
            resp.data.name
          }'s plan is: ON with expiry: ${dateExpiry}`
        );
    }

    await this.getUsers();
  };

  deleteUser = async user_id => {
    const resp = await adminService.deleteUser(user_id);
    if (resp.status === 200) toast.success(`Delete successful.`);
    if (resp.status !== 200) toast.danger(`Delete error. ${resp.data}`);

    await this.getUsers();
  };

  handleOnSubmit = () => {
    const { usernameValue, passwordValue } = this.state;
    if (
      usernameValue === KAS_LOGIN_USERNAME &&
      passwordValue === KAS_LOGIN_PASSWORD
    ) {
      this.setState({ isLoggedIn: true });
      toast.success("Login success");
    } else {
      toast.error("Bad login details");
    }
  };

  handlePasswordChange = event => {
    this.setState({ passwordValue: event.target.value });
  };
  handleUsernameChange = event => {
    this.setState({ usernameValue: event.target.value });
  };

  handleLogInAs = async (user) => {
    const token = await adminService.getImpersonateUserToken(user._id);
    authService.logout();
    authService.loginWithJwt(token, 3);
    window.location.reload()
  }

  handlePlanClick = async user => {
    let newPlan = 0;
    if (user.account_plan === 0) newPlan = 1;
    if (user.account_plan === 1) newPlan = 0;
    this.updateUserPlan(user._id, newPlan, user.account_plan_expiry, user.free_trial);
  };

  handleFreeTrialClick = async user => {
    let freeTrial = 0;
    if (user.free_trial === 0) freeTrial = 1;
    if (user.free_trial === 1) freeTrial = 0;
    this.updateUserPlan(user._id, user.account_plan, user.account_plan_expiry, freeTrial);
  };

  handleUserDeleteClick = user => {
    this.setState({ deleteModal: true, selectedUser: user });
  };

  handleCloseDeleteModal = user => {
    this.setState({ deleteModal: false });
  };

  handleDeleteUserFromModal = async () => {
    const { selectedUser } = this.state;
    await this.deleteUser(selectedUser._id);
    this.setState({ deleteModal: false });
  };

  handleExpiryDateChange = async (date, user) => {
    const newDateFormatted = Moment(date).format("YYYYMMDD");
    this.updateUserPlan(user._id, user.account_plan, newDateFormatted, user.free_trial);
    //post existing account_plan and the new account_plan_expiry
  };

  handleDateSelect = async date => {};

  handleSearch = query => {
    this.setState({ searchQuery: query });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  filterUsers() {
    const { searchQuery, users, sortColumn } = this.state;
    let filtered = users;
    if (searchQuery)
      filtered = users.filter(u =>
        u.email.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sortedAndFiltered = _.orderBy(
      filtered,
      [sortColumn.path],
      [sortColumn.order]
    );

    return sortedAndFiltered;
  }
  constructor(props) {
    super(props);
    this.colour = {
      colour: true
      
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target ;
    alert(target.checked);
    
    }

    
  
  render() {



    
    let count = this.state.users.length;
    const style = {
      backgroundColor: "#cce1ff"
    };
    const {
      isLoggedIn,
      usernameValue,
      passwordValue,
      noUsersFlag,
      users,
      searchQuery,
      sortColumn,
      deleteModal,
      selectedUser
    } = this.state;
    if (noUsersFlag === 1) {
      count = 0;
      //message = "No activated locks for this account.";
    }

    let filteredUsers = this.filterUsers(users);
    const divstyle = {
      textAlign: "left"
    };

    if(this.state.api_log) {
      return <div>
        <button
          type="button"
          className="btn btn-primary position-absolute"
          style={{ right: 10 }}
          onClick={() => {
            this.setState({ api_log: null });
          }}
        >
          Go back
        </button>
        
        <div>
          <h3>API Log Response</h3>

          <h5 className="text-left"><strong>Factory Number: </strong> {this.state.api_log.factoryNumber}</h5>
          <h5 className="text-left"><strong>Actions: </strong> {this.state.api_log.actions}</h5>
          <h5 className="text-left"><strong>Request URL: </strong> {JSON.parse(this.state.api_log.request.kas_json_array).requestBody.uri}</h5>
          <h5 className="text-left"><strong>Request Method: </strong> {JSON.parse(this.state.api_log.request.kas_json_array).requestBody.method}</h5>
          <h5 className="text-left mb-3"><strong>Request Body: </strong> {JSON.stringify(JSON.parse(this.state.api_log.request.kas_json_array).requestBody.body)}</h5>
          <h5 className="text-left"><strong>Response:</strong></h5>
          <p className="text-left mb-3">{this.state.api_log.response.parsed_response}</p>
          {Boolean(this.state.api_log.fallback) && <><h5 className="text-left"><strong>Fallback:</strong></h5>
          <Fallback s_inst_id={this.state.api_log.fallback} /></>}
        </div>
      </div>
    }

    if(this.state.show_api_logs) {
      return (
        <div>
          <button
            type="button"
            className="btn btn-primary position-absolute"
            style={{ right: 10 }}
            onClick={() => {
              this.setState({ show_api_logs: !this.state.show_api_logs });
            }}
          >
            Go back
          </button>

          <table className="table">
            <thead className="font-weight-bold">
              <tr>
                <td>
                  Date/Time
                </td>
                <td>
                  Serial
                </td>
                <td>
                  Action Type
                </td>
                <td>
                  Actions
                </td>
              </tr>
            </thead>
            <tbody>
              {this.state.api_logs.length ? this.state.api_logs.map((indiv_log, index) => {
                return (
                  <tr key={index} valign="middle">
                    <td>
                      {indiv_log.createdAt.toLocaleString(null)}
                    </td>
                    <td>
                      {indiv_log.factoryNumber}
                    </td>
                    <td>
                      {indiv_log.actions}
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          this.setState({ api_log: indiv_log });
                        }}
                      >
                        Show full response
                      </button>
                    </td>
                  </tr>
                )
              }) : (
                <tr>
                  <td colSpan={4}>
                    No Logs Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )
    }



    return (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-primary position-absolute"
          style={{ right: 10 }}
          onClick={() => {
            this.setState({ show_api_logs: !this.state.show_api_logs });
          }}
        >
          View API Logs
        </button>
        <Container style={style}>
          <DeleteModal
            deleteModal={deleteModal}
            onCloseModal={this.handleCloseDeleteModal}
            onDeleteModal={this.handleDeleteUserFromModal}
            selectedUser={selectedUser}
          />
          <Row>
            <h1>Super Admin</h1>
          </Row>
          {isLoggedIn ? (
            count !== 0 ? (
              <Row>
                <p>Some notes:</p>
                <div style={divstyle}>
                  <ul>
                    <li>
                      Every time a new user registers <code>Free Trial</code>{" "}
                      will automatically turn on and{" "}
                      <code>Account Plan Expiry</code> will be set ahead for 1
                      month
                    </li>
                    <li>
                      Managers which get an account created for them do not get
                      a <code>Free Trial</code>. You will have to manually
                      adjust the date picker to 1 month and then toggle the{" "}
                      <code>Account Plan</code> button to <code>1</code>.{" "}
                    </li>
                    <li>
                      Each day, a scanner will run and clean the account plans
                      which have an expiry date which has lapsed.
                    </li>
                    <li>
                      <code>Super Admin Access</code> can only be toggled by people 
                      with database access. Just set the is_super_admin bool
                      value by typing in <code>true</code>, everyone else is{" "}
                      <code>false</code> by default.
                    </li>
                  </ul>
                </div>

                <div className="form-group w-100 p-3">
                  <h6 className="text-left">
                    Console status message: Note : Basic HTML is supported.  Inline styles are not supported, however existing CSS classes are.  Most common classes to use are btn-success2 and btn-danger2. Tip.  Dont forget the closing html tag. :)
                  </h6>
                  <textarea 
                    className="form-control bg-white rounded w-100 p-2 mb-3"
                    style={{
                      padding:'9px',
                      boxSizing:'border-box',
                      fontSize:'15px'
                    }}
                    value={this.state.consoleStatusMessage}
                    onChange={e => {
                      this.setState({ consoleStatusMessage: e.target.value });
                    }}
                  />
                  <button 
                    type="button"
                    className="btn btn-primary btn-sm d-block ml-auto"
                    onClick={async () => {
                      const updated_message = await adminService.updateConsoleMessage(this.state.consoleStatusMessage);
                      if(updated_message) {
                        toast.success("Console message updated");
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
                <form>
        
       
      </form>

                <SearchBox
                  value={searchQuery}
                  onChange={this.handleSearch}
                  placeholder={"Search for an email..."}
                />
                <AdminTable
                  users={filteredUsers}
                  onPlanClick={this.handlePlanClick}
                  onFreeTrialClick={this.handleFreeTrialClick}
                  onDeleteClick={this.handleUserDeleteClick}
                  sortColumn={sortColumn}
                  onSort={this.handleSort}
                  onDateChange={this.handleExpiryDateChange}
                  logInAs={this.handleLogInAs}
                />
              </Row>
            ) : (
              <LoadingTableSpinner />
            )
          ) : (
            <Row>
              <AdminLogin
                usernameValue={usernameValue}
                passwordValue={passwordValue}
                onPasswordChange={this.handlePasswordChange}
                onUsernameChange={this.handleUsernameChange}
                onSubmit={this.handleOnSubmit}
              />
            </Row>
          )}
        </Container>
      </React.Fragment>
    );
  }
}

export default SuperAdmin;
