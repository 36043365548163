import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Alert,
  Spinner,
  Row,
  Col
} from "reactstrap";

class InstructionResultModal extends Component {
  formatAck(ack) {
    const { functionMessage, successMessage } = this.props;
    let instructMessage = this.props.instructionMessage;

    if (instructMessage === undefined) instructMessage = "";
    if (ack === 5)
      return {
        alertColor: "primary",
        message: `Please wait... ${functionMessage}...`,
        spinner: true
      };
    if (ack === 2)
      return {
        alertColor: "warning",
        message: "Failed. Please try again. " + instructMessage + `${ack}`,
        spinner: false
      };
    if (ack === 2501)
      return {
        alertColor: "warning",
        message:
          "Failed. Guest password does not exist." + instructMessage + `${ack}`,
        spinner: false
      };
    if (ack === 2511)
      return {
        alertColor: "warning",
        message: "Failed." + instructMessage + `${ack}`,
        spinner: false
      };
    if (ack === 1) {
      return {
        alertColor: "success",
        message: `Success. ${successMessage}.`,
        spinner: false
      };
    } else if (ack === 8) {
      return {
        alertColor: "warning",
        message:
          "Failed." +
          (instructMessage
            ? instructMessage
            : "No Gateway Found Or Gateway Offline.") +
          " " +
          `${ack}`,
        spinner: false
      };
    } else {
      return {
        alertColor: "warning",
        message:
          `Failed. ${ack} ` +
          (instructMessage
            ? instructMessage
            : "Unknown error. Try reseting the lock with the App and ensure Gateway is online.") +
          " ",
        spinner: false
      };
    }
  }

  render() {
    const { instructionModal, toggleInstructionModal, ack, title } = this.props;

    const { alertColor, message, spinner } = this.formatAck(ack);

    return (
      <Modal isOpen={instructionModal}>
        <Form>
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Alert color={alertColor}>
                <Row>
                  <Col>{message}</Col>
                  <Col>
                    {spinner ? (
                      <Spinner size="lg" type="grow" color="secondary" />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </Alert>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={event => toggleInstructionModal(event)}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default InstructionResultModal;
