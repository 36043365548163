import React, { Component } from "react";
import {
  ModalHeader,
  Modal,
  FormGroup,
  Form,
  ModalBody,
  Row,
  Col,
  Spinner,
  ModalFooter,
  Button,
  Input
} from "reactstrap";

const TextFieldModal = ({
  isOpen,
  isModalLoading,
  title,
  message,
  placeholder = "Enter text here",
  buttonClose,
  buttonClick,
  onUpdate,
  input
}) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <Row>
          <Col>{message}</Col>
        </Row>
        <Row>
          <Col>
            {" "}
            <Input
              placeholder={placeholder}
              onChange={e => onUpdate(e)}
              value={input}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        {isModalLoading ? <Spinner color="primary" /> : null}
        <Button color="secondary" onClick={event => buttonClose(event)}>
          Close
        </Button>
        <Button
          color="primary"
          onClick={event => buttonClick(event)}
          disabled={input.length < 3 ? true : false}
        >
          Update
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TextFieldModal;
