import React, { Component } from "react";
import { Tooltip } from "reactstrap";

class IconHeader extends Component {
  state = {
    tooltipOpen: false
  };

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  };

  render() {
    const { label, iconClass, tooltipId, onClick } = this.props;
    const id = "edit-icon-" + tooltipId;
    return (
      <i className={iconClass} aria-hidden="true" id={id} onClick={onClick}>
        <Tooltip
          placement="top"
          isOpen={this.state.tooltipOpen}
          autohide={false}
          target={id}
          toggle={this.toggle}
        >
          {label}
        </Tooltip>
      </i>
    );
  }
}

export default IconHeader;
