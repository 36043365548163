import http from "./httpService";
import auth from "./authService";
import authService from "./authService";
import { apiUrlProd, apiUrlDev } from "./../config";

let api = "";
if (process.env.NODE_ENV === "production") {
  api = apiUrlProd;
} else if (process.env.NODE_ENV === "development") {
  api = apiUrlDev;
}
const baseURL = api;

const apiEndPoint = baseURL + "/locks";

http.setJwt(auth.getJwt());

export async function getLocks() {
  const resp = await http.get(apiEndPoint);
  return resp.data;
}

export async function updateName(factoryName, name) {
  const data = { l_factory_name: factoryName, l_lock_title: name };
  const resp = await http.put(apiEndPoint, data);
}

export async function remoteUnlock(factoryName) {
  const jwt = authService.getJwt();
  const api = baseURL + `/lockCommand/remoteUnlock/${factoryName}/${jwt}`;
  const resp = await http.get(api);
  return resp;
}

export async function setClock(factoryName) {
  const api = baseURL + `/lockCommand/setClock/${factoryName}`;
  const resp = await http.put(api);
  return resp;
}

export async function getLockStatus(factoryName) {
  const api = baseURL + `/lockCommand/status/${factoryName}`;
  const resp = await http.get(api);
  return resp;
}

export async function getLockPasswords(factoryName) {
  const api = baseURL + `/lockCommand/remotePassword/${factoryName}`;
  const resp = await http.get(api);
  return resp;
}

export async function newLockPassword(factoryName, startTime, endTime, id, password, name, emailChecked, emailInput, emailMessage) {
  const api = baseURL + `/lockCommand/remotePassword/${factoryName}`;
  const data = {
    password_start_time: startTime,
    password_end_time: endTime,
    password_id: id,
    password_name: name,
    password: password,
    email_checked: emailChecked,
    password_email: emailInput,
    password_message: emailMessage
  };
  const resp = await http.post(api, data);
  return resp;
}

export async function deleteLockPassword(factoryName, pwd) {
  const api = baseURL + `/lockCommand/remotePassword/${factoryName}`;
  const data = { password: pwd };
  const resp = await http.delete(api, { data });
  return resp;
}

export async function getLockRFIDs(factoryName) {
  const api = baseURL + `/lockCommand/remoteRFID/${factoryName}`;
  const resp = await http.get(api);
  return resp;
}

export async function newLockRFID(factoryName, startTime, endTime, id, uid, name, isUid) {
  const api = baseURL + `/lockCommand/remoteRFID/${factoryName}`;
  const data = {
    rfid_start_time: startTime,
    rfid_end_time: endTime,
    rfid_id: id,
    rfid_name: name,
    uid: uid,
    kas_uid: uid,
    is_kas_uid: !isUid
  };
  const resp = await http.post(api, data);
  return resp;
}

export async function deleteLockRFID(factoryName, rfid_id) {
  const api = baseURL + `/lockCommand/remoteRFID/${factoryName}/${rfid_id}`;
  const resp = await http.delete(api);
  return resp;
}

export async function getAuditRecords(factoryName) {
  const api = baseURL + `/events/${factoryName}`;
  const resp = await http.get(api);
  return resp;
}

export async function getLockTimezone(factoryName) {
  const api = baseURL + `/timezone/${factoryName}`;
  const resp = await http.get(api);
  return resp;
}

export async function getTimezoneNames(factoryName) {
  const api = baseURL + `/timezone`;
  const resp = await http.get(api);
  return resp;
}

export async function updateLockTimezone(factoryName, timezoneName) {
  const api = baseURL + `/timezone/${factoryName}`;
  const body = {
    l_timezone_name: timezoneName
  };
  const resp = await http.put(api, body);
  return resp;
}

export default {
  getLocks,
  updateName,
  remoteUnlock,
  setClock,
  getLockStatus,
  getLockPasswords,
  newLockPassword,
  deleteLockPassword,
  getLockRFIDs,
  newLockRFID,
  deleteLockRFID,
  getAuditRecords,
  getLockTimezone,
  getTimezoneNames,
  updateLockTimezone
};
