import React from "react";
import { Row, Col, Spinner } from "reactstrap";

const LoadingTableSpinner = () => {
  return (
    <Row>
      <Col xs="auto">
        <Spinner color="secondary" />
      </Col>
      <Col xs="auto">Loading ... </Col>
    </Row>
  );
};

export default LoadingTableSpinner;
