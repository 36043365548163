import React, { useEffect, useState } from 'react'
import axios from 'axios'
import adminService from "../../services/adminService";

export const Fallback = ({ s_inst_id }) => {

    const [fallbackQuery, setFallbackQuery] = useState(null);

    useEffect(() => {
        const query = async () => await adminService.getFallbackLog(s_inst_id);
        query().then((data) => {
        console.log(data);

            setFallbackQuery(data);
        }).catch(err => {
            console.log(err)
        });

    }, [])
    
    return (
        <div className="text-left">
            {Boolean(fallbackQuery) && fallbackQuery.response.raw_response}
        </div>
    )
}
