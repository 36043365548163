import React, { Component } from "react";
import { Button, Tooltip } from "reactstrap";
import authService from "../services/authService";
import Table from "./common/table";
import Like from "./common/like";
import Edit from "./common/edit";
import Clock from "./common/clock";
import { upgradePlanLink, passwordLabel, auditLabel, rfidLabel, keyTypes } from "../config.json";

import IconHeader from "./common/iconHeader";
//import TooltipButton from "./common/tooltipButton";
import TooltipHeader from "./common/tooltipHeader";

class LocksTable extends Component {
  constructor(props) {
    super(props);
    //const user = authService.getCurrentUser();
    this.columns.push(this.status);
    this.columns.push(this.rfidColumn);
    this.columns.push(this.pinColumn);
    this.columns.push(this.auditColumn);
    this.columns.push(this.unlockColumn);
    this.columns.push(this.clock);
    this.columns.push(this.edit);

    //if (user && user.isAdmin) this.columns.push(this.newColumn) //push an admin column to the table
  }

  // componentDidMount() {
  //   if (this.props.account_plan === 1) this.setState({ tooltips });
  // }

  state = {
    upSellText: (
      <span>
        Upgrade to Paid plan to enable this feature.{" "}
        <a href={upgradePlanLink} target="_blank">
          Find out more
        </a>
      </span>
    )
  };

  columns = [
    {
      path: "l_lock_title",
      label: "Lock Title"
    },
    {
      path: "l_factory_name",
      label: "Factory Number"
    },
    {
      path: "keyType",
      label: "Type"
    }
  ];

  status = {
    key: "lastOnline",
    label: "Online Status",
    content: lock => (
      <Button
        onClick={() => this.props.onStatusCheck(lock)}
        color="primary"
        className="btn btn-sm"
        disabled={this.props.account_plan}
      >
        Status
      </Button>
    )
  };

  edit = {
    key: "edit",
    icon: (
      <IconHeader
        iconClass="fa fa-cog"
        label="Edit Lock Settings"
        tooltipId="settings"
      />
    ),
    content: lock => {
      return lock.keyType === keyTypes.admin ? (
        <Edit onClick={() => this.props.onUpdate(lock)} />
      ) : null;
    }
  };

  clock = {
    key: "clock",
    icon: (
      <IconHeader
        iconClass="fa fa-clock-o"
        label="Set Clock"
        tooltipId="clock"
      />
    ),
    content: lock => (
      <Clock
        onClick={() => this.props.onClockSet(lock)}
        tooltipId={lock.l_factory_name}
        lockName={lock.l_lock_title}
      />
      // <Like liked={movie.liked} onClick={() => this.props.onLike(movie)} />
    )
  };

  like = {
    key: "like",
    content: lock => (
      <Like />
      // <Like liked={movie.liked} onClick={() => this.props.onLike(movie)} />
    )
  };

  rfidColumn = {
    key: "rfid",
    tooltip: (
      <TooltipHeader
        // className="fa fa-clock-o"
        tooltipDisabled={this.props.tooltipDisabled}
        label={rfidLabel}
        tooltipId="rfid"
        tooltipText={this.state.upSellText}
      />
    ),
    // label: `${rfidLabel}s`,
    content: lock => (
      <Button
        onClick={() => this.props.onRFID(lock)}
        className="btn btn-secondary btn-sm"
        disabled={this.props.buttonDisabled}
      >
        RFID
      </Button>
    )
  };

  pinColumn = {
    key: "pin",
    tooltip: (
      <TooltipHeader
        // className="fa fa-clock-o"
        tooltipDisabled={this.props.tooltipDisabled}
        label={passwordLabel}
        tooltipId="pincode"
        tooltipText={this.state.upSellText}
      />
    ),
    //label: `${passwordLabel}s`,
    content: lock => (
      <Button
        onClick={() => this.props.onPin(lock)}
        className="btn btn-success btn-sm"
        disabled={this.props.buttonDisabled}
      >
        {passwordLabel}s
      </Button>
    )
  };

  unlockColumn = {
    key: "unlock",
    label: "Remote Unlock",
    content: lock => (
      <Button
        onClick={() => this.props.onUnlock(lock)}
        className="btn btn-info btn-sm"
      >
        Unlock
      </Button>
    )
  };

  auditColumn = {
    key: "audit",
    tooltip: (
      <TooltipHeader
        // className="fa fa-clock-o"
        tooltipDisabled={this.props.tooltipDisabled}
        label={auditLabel}
        tooltipId="audit"
        tooltipText={this.state.upSellText}
      />
    ),
    //label: `${auditLabel}`,
    content: lock => {
      // let buttonIsEnabled = this.buttonIsEnabled();
      // buttonIsEnabled = true;
      return (
        <Button
          onClick={() => this.props.onAudit(lock)}
          className="btn btn-warning btn-sm"
          disabled={this.props.buttonDisabled}
        >
          Records
        </Button>
      );
    }
  };

  render() {
    const { locks, onSort, sortColumn } = this.props;
    return (
      <Table
        columns={this.columns}
        data={locks}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default LocksTable;
