import React from "react";
import { Redirect } from "react-router-dom";
import authService from "../../services/authService";

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  const user = authService.getCurrentUser();
  if (!user) {
    return <Redirect to="/login" />;
  } else {
    return <Component {...rest} />;
  }
};

export default ProtectedRoute;
