import React, { Component } from "react";
import Table from "../common/table";
import { Button } from "reactstrap";
import Moment, { isValid } from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class AdminTable extends Component {
  columns = [
    {
      path: "name",
      label: "User Name"
    },
    {
      path: "email",
      label: "Email"
    },
    {
      path: "property_name",
      label: "Property Name"
    },
    {
      path: "date_registered",
      label: "Date Registered",
      content: user => {
        return this.formatDate(user.date_registered);
      }
    },
    {
      path: "is_super_admin",
      label: "Super Admin Access",
      content: user => {
        if (user.is_super_admin) return "True";
        return "False";
      }
    },
    {
      path: "account_plan",
      label: "Account Plan"
    },
    {
      path: "free_trial",
      label: "Free Trial"
    },
    {
      path: "account_plan_expiry",
      label: "Account Plan Expiry",
      content: user => {
        let date = new Date(user.account_plan_expiry);
        const { onDateChange } = this.props;
        if (!user.account_plan_expiry) date = new Date();
        return (
          <DatePicker
            selected={date}
            onChange={event => onDateChange(event, user)}
            dateFormat="d MMM yyyy"
          />
        );
      }
    },
    {
      path: "togglePlan",
      label: "Toggle Account Plan",
      content: event => (
        <Button
          onClick={() => this.props.onPlanClick(event)}
          className="btn-primary btn-sm"
        >
          Toggle Plan
        </Button>
      )
    },
    {
      path: "toggleFreeTrial",
      label: "Toggle Free Trial",
      content: event => (
        <Button
          onClick={() => this.props.onFreeTrialClick(event)}
          className="btn-primary btn-sm"
        >
          Toggle Free Trial
        </Button>
      )
    },
    {
      path: "logInAccess",
      label: "Impersonate Access",
      content: user => (
        <Button
          onClick={() => this.props.logInAs(user)}
          className="btn btn-success btn-sm"
        >
          Log In As {user.name}
        </Button>
      )
    },
    {
      path: "deleteUserDevices",
      label: "Delete User & Devices",
      content: event => (
        <Button
          onClick={() => this.props.onDeleteClick(event)}
          className="btn btn-danger btn-sm"
        >
          Delete User and Devices
        </Button>
      )
    }
  ];

  formatDate(date) {
    return date === undefined
      ? ""
      : Moment(new Date(date)).format("D MMM YYYY h:mm a");
  }

  constructor() {
    super();
  }
  render() {
    const { users, onSort, sortColumn } = this.props;
    return (
      <Table
        columns={this.columns}
        data={users}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default AdminTable;
