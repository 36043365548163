import http from "./httpService";
import auth from "./authService";
import { toast } from "react-toastify";
import authService from "./authService";
import { apiUrlProd, apiUrlDev } from "./../config";

let api = "";
if (process.env.NODE_ENV === "production") {
  api = apiUrlProd;
} else if (process.env.NODE_ENV === "development") {
  api = apiUrlDev;
}
const baseURL = api;

const apiEndPoint = baseURL + "/users";

http.setJwt(auth.getJwt());

export async function getUsers() {
  const resp = await http.get(apiEndPoint);
  return resp.data;
}

export async function updateUser(user_id, account_plan, account_plan_expiry, free_trial) {
  const data = { account_plan, account_plan_expiry, free_trial };
  const resp = await http.put(`${apiEndPoint}/${user_id}`, data);
  return resp;
}

export async function deleteUser(user_id) {
  const data = { delete_locks: true, delete_gateways: true };
  const resp = await http.delete(`${apiEndPoint}/${user_id}`, { data });
  return resp;
}

export async function getUser() {
  const resp = await http.get(`${apiEndPoint}/me`);
  return resp;
}

export async function updateUserDetails(user_id, input, type) {
  const data = { [type]: input };
  const resp = await http.put(`${apiEndPoint}/account/${user_id}`, data);
  return resp;
}

export async function getImpersonateUserToken(user_id) {
  const resp = await http.get(`${apiEndPoint}/impersonate/${user_id}`);
  return resp.data;
}
export async function getApiKey() {
  const resp = await http.get(`${apiEndPoint}/static-api-key`);
  return resp.data;
}

export async function refreshApiKey() {
  await http.post(`${apiEndPoint}/static-api-key`);
}

export async function updateConsoleMessage(message) {
  const resp = await http.post(baseURL + "/superAdmin/update-status-message", { message });
  return resp.data;
}
export async function getConsoleMessage() {
  const resp = await http.get(baseURL + "/superAdmin/get-status-message");
  return resp.data;
}
export async function updateConsoleColour(colourBool) {
  const resp = await http.post(baseURL + "/superAdmin/update-status-colour", { colourBool });
  return resp.data;
}
export async function getConsoleColour() {
  const resp = await http.get(baseURL + "/superAdmin/get-status-colour");
  return resp.data;
}

export async function getAPILogs() {
  const resp = await http.get(baseURL + "/superAdmin/get-api-logs");
  return resp.data;
}

export async function getFallbackLog(s_inst_id) {
  const resp = await http.get(baseURL + "/superAdmin/get-fallback-log/" + s_inst_id);
  return resp.data;
}

export default {
  getUsers,
  getApiKey,
  refreshApiKey,
  updateUser,
  deleteUser,
  getUser,
  updateUserDetails,
  getImpersonateUserToken,
  updateConsoleMessage,
  updateConsoleColour,
  getConsoleMessage,
  getConsoleColour,
  getAPILogs,
  getFallbackLog
};
