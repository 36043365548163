import React, { Component } from "react";

const Edit = props => {
  let classes = "fa fa-cog";
  //if (!props.liked) classes += "-o";

  return (
    <i
      className={classes}
      style={{ cursor: "pointer" }}
      onClick={props.onClick}
    />
    // <i
    //   onClick={props.onClick}
    //   style={{ cursor: "pointer" }}
    //   className={classes}
    //   aria-hidden="true"
    // />
  );
};

export default Edit;
