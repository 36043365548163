import http from "./httpService";
import auth from "./authService";
import authService from "./authService";
import { apiUrlProd, apiUrlDev } from "./../config";

let api = "";
if (process.env.NODE_ENV === "production") {
  api = apiUrlProd;
} else if (process.env.NODE_ENV === "development") {
  api = apiUrlDev;
}
const baseURL = api;

const apiEndpoint = baseURL + "/gateways";

http.setJwt(auth.getJwt());

export async function getGateways() {
  const resp = await http.get(apiEndpoint);
  return resp.data;
}

export async function getGatewayStatus(factoryName) {
  const resp = await http.get(`${apiEndpoint}/status/${factoryName}`);
  return resp.data;
}

export default {
  getGateways,
  getGatewayStatus
};
