import React, { Component } from "react";
import TopNavBar from "./components/navbar";
import Locks from "./components/locks";
import RFID from "./components/rfid";
import Pincode from "./components/pincode";
import Audit from "./components/audit";
import Gateways from "./components/gateways";
import NotFound from "./components/notFound";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "./components/common/protectedRoute";

import { Container } from "reactstrap";
import Account from "./components/account";
import LoginForm from "./components/loginForm";
import authService from "./services/authService";
import Logout from "./components/logout";
import SuperAdmin from "./components/admin/admin";
import adminService from "./services/adminService";
import AccountIntegration from "./components/integration/IntegrationMain";
import AccountPlan from "./components/account/AccountPlan";
import LogsMain from "./components/integration/LogsMain";
import ApiKeyMain from "./components/apiKey/ApiKeyMain";
import ClockEngine from "./components/clockEngine/ClockEngine";
import SuperAdminProtectedRoute from './components/common/superAdminProtectedRoute';
import ApiKeyStatic from './components/apiKey/ApiKeyStatic';
export const AppContext = React.createContext();

class App extends Component {
  state = {
    show_console_message: true
  };

  componentDidMount() {
    this.getUser();
  }
  
  async getUser() {
    const user = authService.getCurrentUser();
    this.setState({ user });
    await this.getUserName();
  }

  async getUserName() {
    const resp = await adminService.getUser();
    this.setState({ name: resp.data.name });
  }

  render() {
    const { user, name } = this.state;

    let show_console_message = this.state.show_console_message;

    const set_show_console_message = (value) => {
      this.setState({ show_console_message: value });
    }

    
    return (
      <div className="App">
        <AppContext.Provider value={{ show_console_message, set_show_console_message }}>
          <ToastContainer />
          <TopNavBar user={user} name={name} />
          <Container>
            <Switch>
              <Route path="/login" component={LoginForm} />
              <Route path="/logout" component={Logout} />
              <Route path="/not-found" component={NotFound} />

              <SuperAdminProtectedRoute path="/superAdmin" component={SuperAdmin} />

              <ProtectedRoute path="/locks/rfid/:factoryName" component={RFID} />
              <ProtectedRoute path="/locks/rfid" component={RFID} />

              <ProtectedRoute path="/locks/pincode/:factoryName" component={Pincode} />
              <ProtectedRoute path="/locks/pincode" component={Pincode} />

              <ProtectedRoute path="/locks/audit/:factoryName" component={Audit} />
              <ProtectedRoute path="/locks/audit" component={Audit} />

            <ProtectedRoute path="/locks" component={Locks} />
            <ProtectedRoute path="/gateways" component={Gateways} />
            <ProtectedRoute path="/account/apiKey" component={ApiKeyMain} />
            <ProtectedRoute path="/account/staic-api-key" component={ApiKeyStatic} />
            <ProtectedRoute path="/account/clockEngine" component={ClockEngine} />
            <ProtectedRoute path="/account" component={Account} />

              <ProtectedRoute path="/integrations/logs/:service" component={LogsMain} />
              <ProtectedRoute path="/integrations" component={AccountIntegration} />
              <ProtectedRoute path="/plans" component={AccountPlan} />

              {user && <Redirect from="/" exact to="/locks" />}
              {!user && <Redirect from="/" exact to="/login" />}
              <Redirect to="/not-found" />
            </Switch>
          </Container>
        </AppContext.Provider>
      </div>
    );
  }
}

export default App;
