import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner
} from "reactstrap";
import LockStatusTable from "../components/statusTable";
import _ from "lodash";

class StatusModal extends Component {
  formatBody(lockStatus) {
    const { statusFlag, onSort, sortColumn, selectedLock } = this.props;
    if (!statusFlag) return <Spinner type="grow" color="primary" />;
    if (lockStatus.l_status.length === 0)
      return "No activated Gateway(s) are nearby this lock. The lock is not online.";
    console.log(lockStatus.l_status);

    const status = this.filterLockStatus(lockStatus.l_status);

    return (
      <LockStatusTable
        status={status}
        sortColumn={sortColumn}
        onSort={onSort}
        selectedLock={selectedLock}
      />
    );
  }

  filterLockStatus(lockStatus) {
    const { sortColumn } = this.props;

    const sortedAndFiltered = _.orderBy(
      lockStatus,
      [sortColumn.path],
      [sortColumn.order]
    );

    return sortedAndFiltered;
  }

  render() {
    const {
      statusModal,
      closeStatusModal,
      selectedLock,
      lockStatus
    } = this.props;
    let bodyData = this.formatBody(lockStatus);

    return (
      <Modal isOpen={statusModal} size="lg" className="">
        <ModalHeader>
          Lock Status: {selectedLock.l_lock_title} -{" "}
          {selectedLock.l_factory_name}
        </ModalHeader>
        <ModalBody>{bodyData}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={event => closeStatusModal(event)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default StatusModal;
