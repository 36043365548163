import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

class DeleteModal extends Component {
  state = {};
  render() {
    const {
      deleteModal,
      onCloseModal,
      onDeleteModal,
      selectedUser
    } = this.props;

    return (
      <Modal isOpen={deleteModal} size="lg" className="">
        <ModalHeader>
          Delete User {selectedUser ? selectedUser.email : ""}
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete this user and ALL associated locks and
          gateways? Be very sure!
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={event => onDeleteModal(event)}>
            Yes, Delete All - {selectedUser ? selectedUser.email : ""}
          </Button>
          <Button color="secondary" onClick={event => onCloseModal(event)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default DeleteModal;
