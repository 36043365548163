import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  Button,
  Input,
  CardHeader
} from "reactstrap";
import logo from "../../../assets/cloudbeds.png";
import IntegrationSwitch from "../common/integrationSwitch";

const CloudbedsAccordionItem = props => {
  return (
    <Card>
      {/* <AccordionItemPanel style={{ backgroundColor: "#748491" }}> */}
      <CardHeader tag="h4">Cloudbeds Integration</CardHeader>
      <CardBody>
        <Row style={props.style}>
          <Col sm="12">
            <Row>
              <Col sm="10">
                <img src={logo} alt="image" height="35px" align="left" />
              </Col>
              <Col sm="2" style={{ textAlign: "left" }} />
            </Row>
            <h3>Coming soon...</h3>
          </Col>
        </Row>
      </CardBody>
      {/* <CardFooter className="text-muted" style={{ textAlign: "right" }} /> */}
    </Card>
  );
};

export default CloudbedsAccordionItem;
