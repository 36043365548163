import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import RFIDTable from "./rfidTable";
import lockService from "../services/lockService";
import LoadingTableSpinner from "./common/loadingTableSpinner";
import { rfidLabel } from "../config.json";
import Moment from "moment";
import InstructionResultModal from "./common/instructionResultModal";
import NewRFIDModal from "./rfidModal";
import InfoModal from "./common/infoModal";
import _ from "lodash";

class RFID extends Component {
  state = {
    rfids: [],
    modalNewRFID: false,
    serverFlag: 0,
    options: [],
    rfidNameInputValue: "",
    rfidInputValue: "",
    startDate: new Date(),
    endDate: Moment(new Date())
      .add(1, "day")
      .toDate(),
    rfidIndex: 101,
    instructionAck: 5,
    instructionModal: false,
    instructionMessage: "",
    emailChecked: false,
    emailMessage: "",
    emailInput: "",
    newRFIDModal: false,
    infoModal: false,
    isUID: false,
    sortColumn: { path: "rfid_id", order: "desc" }
  };

  componentDidMount() {
    const factoryName = this.props.computedMatch.params.factoryName;
    this.loadRFIDData(factoryName);
  }

  async loadRFIDData(factoryName) {
    const resp = await lockService.getLockRFIDs(factoryName);
    const lockTitle = resp.data.l_lock_title;
    this.setState({
      rfids: resp.data.l_rfid,
      factoryName,
      lockTitle,
      serverFlag: 1
    });
    this.formatOptions();
  }

  async formatOptions() {
    const options = this.generateArray(1899);
    const { rfids } = this.state;
    rfids.map(rfid => {
      _.pull(options, rfid.rfid_id);
    });
    this.setState({ options: this.generateArray(1899), rfidIndex: options[0] });
  }

  handleDelete = async rfid => {
    const factoryName = this.state.factoryName;
    const rfid_id = rfid.rfid_id;
    this.setState({ instructionModal: true, instructionAck: 5 });
    const resp = await lockService.deleteLockRFID(factoryName, rfid_id);
    this.setState({
      instructionAck: resp.data.ack,
      instructionMessage: resp.data.message
    });
    this.loadRFIDData(factoryName);
  };

  handleSubmitForm = async () => {
    const {
      factoryName,
      startDate,
      endDate,
      rfidNameInputValue,
      rfidInputValue,
      rfidIndex,
      isUID,
      rfids
    } = this.state;
    const startString = Moment(new Date(startDate)).format("YYMMDDHHmm");
    const endString = Moment(new Date(endDate)).format("YYMMDDHHmm");

    this.setState({
      newRFIDModal: true,
      modalNewRFID: false,
      instructionAck: 5
    });

    const resp = await lockService.newLockRFID(
      factoryName,
      startString,
      endString,
      rfidIndex,
      rfidInputValue,
      rfidNameInputValue,
      isUID
    );
    if (resp.status === 400)
      return this.setState({
        instructionAck: 2,
        instructionMessage: resp.data
      });
    this.setState({
      instructionAck: resp.data.ack,
      instructionMessage: resp.data.message
    });

    if (resp.data.l_rfid === undefined)
      return this.setState({ modalNewRFID: false });
    this.setState({ modalNewRFID: false, rfids: resp.data.l_rfid });
    this.formatOptions();
  };

  generateArray = n => [...Array(n)].map((_, index) => index + 101);

  onNewRFIDClick = () => {
    this.setState({ modalNewRFID: true, isUID: false });
  };

  handleStartChange = date => {
    this.setState({
      startDate: date
    });
  };

  handleEndChange = date => {
    this.setState({
      endDate: date
    });
  };

  handleCloseModal = () => {
    this.setState({ modalNewRFID: false });
  };

  handleInfoModalClose = () => {
    this.setState({ infoModal: false });
  };

  handleRFIDNameChange = event => {
    this.setState({
      rfidNameInputValue: event.target.value
    });
  };

  handleRFIDChange = event => {
    this.setState({
      rfidInputValue: event.target.value
    });
  };

  handleOptionsChange = event => {
    this.setState({
      rfidIndex: parseInt(event.target.value)
    });
  };

  toggleInstructionModal = () => {
    this.setState(prevState => ({
      instructionModal: false,
      newRFIDModal: false
    }));
  };

  handleMessageChange = event => {
    this.setState({
      emailMessage: event.target.value
    });
  };

  handleEmailChange = event => {
    this.setState({
      emailInput: event.target.value
    });
  };

  handleCheckChange = () => {
    this.setState(prevState => ({
      emailChecked: !prevState.emailChecked
    }));
  };

  handleInfoModalClick = () => {
    this.setState({ infoModal: true });
  };

  handleUIDChange = () => {
    this.setState(prevState => ({
      isUID: !prevState.isUID,
      rfidInputValue: ""
    }));
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  formatPage() {
    if (this.state.serverFlag === 0) return <LoadingTableSpinner />;
    const count = this.state.rfids.length;
    if (count === 0)
      return `No ${rfidLabel}s for this lock. Click New ${rfidLabel} above to create one.`;
    const { sortColumn } = this.state;
    const sortedRFIDs = this.filterAndSort(this.state.rfids);
    return (
      <RFIDTable
        rfids={sortedRFIDs}
        onDelete={this.handleDelete}
        sortColumn={sortColumn}
        onSort={this.handleSort}
      />
    );
  }

  filterAndSort(rfids) {
    const { sortColumn } = this.state;
    const sortedAndFiltered = _.orderBy(
      rfids,
      [sortColumn.path],
      [sortColumn.order]
    );
    return sortedAndFiltered;
  }

  render() {
    const style = {
      padding: 20
    };
    const {
      lockTitle,
      factoryName,
      options,
      instructionModal,
      instructionAck,
      newRFIDModal,
      instructionMessage,
      infoModal,
      ...rest
    } = this.state;

    const body = (
      <React.Fragment>
        <p>
          Each RFID Card supplied will have a printed 10 digit number. Enter
          this number in the field to uniquely identify the RFID card.{" "}
        </p>
        <p>Note: this is an encrypted number for security and NOT the UID.</p>
        <p>
          If you want to enter the UID directly, ensure you enable the Direct
          UID Input button.
        </p>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <Container>
          <InfoModal
            infoModal={infoModal}
            onClose={this.handleInfoModalClose}
            title="Find the RFID Card Number"
            body={body}
            image="image"
            buttonText="Got it"
          />
          <InstructionResultModal
            instructionModal={instructionModal}
            toggleInstructionModal={this.toggleInstructionModal}
            ack={instructionAck}
            instructionMessage={instructionMessage}
            title={`Delete ${rfidLabel}`}
            functionMessage={`Deleting ${rfidLabel}`}
            successMessage={`${rfidLabel} Delete Successfully`}
          />
          <InstructionResultModal
            instructionModal={newRFIDModal}
            toggleInstructionModal={this.toggleInstructionModal}
            ack={instructionAck}
            instructionMessage={instructionMessage}
            title={`Add ${rfidLabel}`}
            functionMessage={`Adding ${rfidLabel}`}
            successMessage={`${rfidLabel} Added Successfully`}
          />
          <NewRFIDModal
            lockTitle={lockTitle}
            factoryName={factoryName}
            closeModal={this.handleCloseModal}
            options={options}
            onRFIDChange={this.handleRFIDChange}
            onRFIDNameChange={this.handleRFIDNameChange}
            handleEndChange={this.handleEndChange}
            handleStartChange={this.handleStartChange}
            onOptionsChange={this.handleOptionsChange}
            onSubmit={this.handleSubmitForm}
            onCheckChange={this.handleCheckChange}
            onMessageChange={this.handleMessageChange}
            onEmailChange={this.handleEmailChange}
            onInfoClick={this.handleInfoModalClick}
            onUIDChange={this.handleUIDChange}
            {...rest}
          />
          <Row>
            <h2>
              <Link to="/locks">Locks</Link> > {rfidLabel}s: {lockTitle} -{" "}
              {factoryName}
            </h2>
          </Row>
          <Row style={style}>
            <Button
              onClick={() => this.onNewRFIDClick()}
              className="btn btn-success"
            >
              New {rfidLabel}
            </Button>
            <br />
          </Row>
          <Row>{this.formatPage()}</Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default RFID;
