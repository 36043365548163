import React, { Component } from "react";
import { Button } from "reactstrap";
import Table from "./common/table";
import Moment from "moment";
import { rfidLabel } from "../config.json";

class RFIDTable extends Component {
  columns = [
    {
      path: "rfid_id",
      label: "ID#"
    },
    {
      path: "rfid_name",
      label: `Name/Remark`
    },
    {
      path: "uid",
      label: `${rfidLabel}`
    },
    {
      path: "rfid_start_time",
      label: "Start Time",
      content: rfid => {
        return this.formatDate(rfid.rfid_start_time);
      }
    },
    {
      path: "rfid_end_time",
      label: "End Time",
      content: rfid => {
        return this.formatDate(rfid.rfid_end_time);
      }
    }
  ];

  deleteRFID = {
    key: "deleteRFID",
    label: `Delete ${rfidLabel}`,
    content: rfid => (
      <Button
        onClick={() => this.props.onDelete(rfid)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </Button>
    )
  };

  formatDate(dateString) {
    const date = Moment(dateString, "YYMMDDHHmm").toDate();
    Moment.locale("en");
    return Moment(new Date(date)).format("D MMM YYYY h:mm a");
  }

  constructor() {
    super();
    this.columns.push(this.deleteRFID);
  }

  render() {
    const { rfids, onSort, sortColumn } = this.props;
    if (rfids.length === 0) return "No RFIDs";
    return (
      <Table
        columns={this.columns}
        data={rfids}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default RFIDTable;
