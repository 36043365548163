import React from "react";

const IntegrationBadge = ({ bool }) => {
  const formatClass = bool => {
    return bool ? "-success" : "-danger";
  };
  return (
    <div style={{ textAlign: "center" }}>
      <div className={`alert alert${formatClass(bool)}`}>
        <strong>{bool ? "Status: Enabled" : "Status: Off"}</strong>
      </div>
    </div>
  );
};

export default IntegrationBadge;
