import React, { Component } from "react";
import { Tooltip } from "reactstrap";

class TooltipHeader extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    const { tooltipDisabled, label, tooltipId, tooltipText } = this.props;
    const id = "key-" + tooltipId;
    return (
      <div>
        <span id={id}> {label}</span>
        {tooltipDisabled ? null : (
          <Tooltip
            placement="top"
            isOpen={this.state.tooltipOpen}
            autohide={false}
            target={id}
            toggle={this.toggle}
          >
            {tooltipText}
          </Tooltip>
        )}
      </div>
    );
  }
}

export default TooltipHeader;
