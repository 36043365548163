import React, { Component } from "react";
import { rfidLabel } from "../config.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CommonForm from "./common/commonForm";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox
} from "availity-reactstrap-validation";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Spinner
} from "reactstrap";

class NewRFIDModal extends CommonForm {
  constructor(props) {
    super(props);
  }

  formatUIDLabel() {
    if (this.props.isUID) return "UID";
    return "Number";
  }
  formatTextInput() {
    if (this.props.isUID) return "text";
    return "number";
  }

  formatValidate() {
    if (this.props.isUID)
      return {
        pattern: { value: "[0-9A-Fa-f]{8}" },
        minLength: { value: 8 },
        maxLength: { value: 8 }
      };
    return {
      minLength: { value: 10 },
      maxLength: { value: 10 }
    };
  }

  render() {
    const {
      modalNewRFID,
      factoryName,
      lockTitle,
      rfidNameInputValue,
      onRFIDNameChange,
      rfidInputValue,
      onRFIDChange,
      closeModal,
      onSubmit,
      options,
      handleStartChange,
      handleEndChange,
      startDate,
      endDate,
      rfidIndex,
      onOptionsChange,
      onInfoClick,
      emailChecked,
      onCheckChange,
      onEmailChange,
      onMessageChange,
      emailInput,
      emailMessage,
      isKasUID,
      onUIDChange
    } = this.props;
    const colWidth = "3";
    return (
      <Modal isOpen={modalNewRFID} size="lg">
        <AvForm onValidSubmit={event => onSubmit(event)}>
          <ModalHeader>
            New {rfidLabel} - Lock Name: {lockTitle} - Factory Name:{" "}
            {factoryName}
          </ModalHeader>
          <ModalBody>
            <AvGroup>
              <Row>
                <Col xs={colWidth}>
                  <Label for="lockTitle">Name or Remark</Label>
                </Col>
                <Col>
                  <AvInput
                    type="text"
                    name="rfidTitle"
                    id="rfidTitle"
                    value={rfidNameInputValue}
                    onChange={event => onRFIDNameChange(event)}
                    placeholder={`Enter a name or remark for this ${rfidLabel}`}
                    required
                  />
                  <AvFeedback>This is an error!</AvFeedback>
                </Col>
              </Row>
            </AvGroup>
            <AvGroup>
              <Row>
                <Col xs={colWidth}>
                  <Label for="lockTitle">
                    {rfidLabel} {this.formatUIDLabel()}{" "}
                    <Button
                      type="button"
                      size="sm"
                      className="btn-info"
                      onClick={event => onInfoClick(event)}
                    >
                      What's This?
                    </Button>
                  </Label>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="UIDChecked"
                      checked={isKasUID}
                      onChange={onUIDChange}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="UIDChecked"
                    >
                      Direct UID Input
                    </label>
                  </div>
                </Col>
                <Col>
                  <AvInput
                    type={this.formatTextInput()}
                    name="rfid"
                    id="rfid"
                    value={rfidInputValue}
                    onChange={event => onRFIDChange(event)}
                    // onChange={event => updateInputValue(event)}
                    required
                    placeholder={`Enter ${rfidLabel} ${this.formatUIDLabel()}`}
                    validate={this.formatValidate()}
                  />
                  <AvFeedback>
                    Input must be 10 digits for RFID Number and 8 characters for
                    UID
                  </AvFeedback>
                </Col>
              </Row>
            </AvGroup>
            <FormGroup>
              <Row>
                <Col xs={colWidth}>
                  <Label for="startTime">Start Time </Label>
                </Col>
                <Col>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeCaption="Start Time"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xs={colWidth}>
                  <Label for="startTime">End Time</Label>
                </Col>
                <Col>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeCaption="End Time"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col xs={colWidth}>
                  <Label for="rfid_id">Index No: #</Label>
                </Col>
                <Col>
                  <Input
                    type="select"
                    name="rfid_id"
                    className="form-control"
                    id="rfid_id"
                    onChange={event => onOptionsChange(event)}
                    value={rfidIndex}
                  >
                    {options.map(option => (
                      <option key={option}>{option}</option>
                    ))}
                  </Input>
                  Note:
                  <small id="emailHelp" className="form-text text-muted">
                    NEO GEN2 BATTERY DOOR LOCK: This number should be between 101 and 200. If you enter a higher number the request will fail.
                  </small>
                  <small id="emailHelp" className="form-text text-muted">
                    NEO CLOUD ACCESS CONTROL READER: This number should be between 101 and 1999
                  </small>
                </Col>
              </Row>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            {/* <p>Please wait.. Uploading Pincode...</p>
              <Spinner size="lg" type="grow" color="primary" /> */}
            <FormGroup>
              <Button color="primary">Submit</Button>{" "}
              <Button color="secondary" onClick={event => closeModal(event)}>
                Cancel
              </Button>
            </FormGroup>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }
}

export default NewRFIDModal;
