import React, { Component } from "react";
import Table from "./common/table";
import { Button } from "reactstrap";
import Moment, { isValid } from "moment";

class GatewaysTable extends Component {
  state = {};

  columns = [
    {
      path: "g_gateway_title",
      label: "Gateway Title"
    },
    {
      path: "g_factory_name",
      label: "Factory Number"
    },
    {
      path: "l_latest_scan_time",
      label: "Last Online (System time)",
      content: gateway => {
        Moment.locale("en");
        const format = "D MMM YYYY h:mm a";
        return Moment(new Date(gateway.l_latest_scan_time)).format(format);
      }
    },
    {
      path: "online-history",
      label: "Online History",
      content: gateway => (
        <Button
          onClick={() => this.props.onStatusClick(gateway)}
          className="btn btn-secondary btn-sm"
        >
          View History
        </Button>
      )
    }
  ];

  constructor() {
    super();
  }
  render() {
    const { gateways, onSort, sortColumn } = this.props;
    return (
      <Table
        columns={this.columns}
        data={gateways}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default GatewaysTable;
