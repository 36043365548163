import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Alert, Row, Col, CardBody, Card, CardHeader, Button, Spinner, CardFooter, FormGroup, Label } from "reactstrap";
import authService from "../../services/authService";
import adminService from "../../services/adminService";
import moment from "moment";
import _ from "lodash";
import { freeTierLabel, paidTierLabel, upgradePlanLink } from "./../../config.json";
import AccountFooter from "../common/accountFooter";

import integrationService from "../../services/integrationService";
import IntegrationModal from "../integration/common/modal";
import UserPlanAlert from "../integration/common/UserPlanAlert";
import IntegrationBadge from "../integration/common/badge";
import OnOffSwitch from "../integration/common/switch";

class ClockEngine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      integrationState: false,
      spinnerIsOn: false,
      integrationSwitch: false,
      modalIsOpen: false,
      modalMessage: "",
      showLogs: false,
      service: "clockEngine",
      logsButtonTitle: "View/Hide Logs"
    };
  }

  componentDidMount() {
    this.getData();
    const user = authService.getCurrentUser();
    this.getUserDetails(user._id);
    this.getLogs(this.state.service);
  }

  async getUserDetails(user_id) {
    const resp = await adminService.getUser(user_id);

    this.setState({ user: resp.data });
  }

  async getLogs(service) {
    const account = await integrationService.getIntegrationAccountLogs();
    this.setState({ account, service });
  }

  async getData() {
    const resp = await integrationService.getIntegrationAccount();
    if (resp.clockEngine) {
      this.setState({
        integrationState: resp.clockEngine.serviceIsOn,
        integrationSwitch: resp.clockEngine.serviceIsOn
      });
    }
  }

  handleSwitchChange = async () => {
    this.setState({ spinnerIsOn: true });
    try {
      const data = await integrationService.putIntegrationAccountClockEngine({
        clockEngine: {
          serviceIsOn: !this.state.integrationSwitch
        }
      });
      if (data.clockEngine) {
        this.setState({
          apiKey: data.clockEngine.api_key,
          integrationState: data.clockEngine.serviceIsOn,
          integrationSwitch: data.clockEngine.serviceIsOn
        });
      }
    } catch (err) {
      this.setState({
        modalIsOpen: true,
        modalMessage: "Sorry, this account is on a free plan. You can only enable this feature on a paid plan."
      });
    }

    this.setState({ spinnerIsOn: false });
  };

  handleToggleModal = () => {
    this.setState(prevState => {
      return {
        modalIsOpen: !prevState.modalIsOpen
      };
    });
  };

  hideShowLogs = () => {
    this.setState(prevState => {
      return {
        showLogs: !prevState.showLogs
      };
    });
  };

  renderNoLogsAlert() {
    return (
      <Alert color="warning">
        No logs at this time. Please enable an Integration service. Note: a{" "}
        <a href={upgradePlanLink} target="_blank" rel="noopener noreferrer">
          Paid Plan
        </a>{" "}
        is required to enable an Integration service.
      </Alert>
    );
  }

  renderLogs(service, style) {
    if (!this.state.account) return "No logs found for this account.";
    const logs = this.state.account[service].logs;
    const sortedLogs = _.sortBy(logs).reverse();
    return sortedLogs.map(item => (
      <Card style={style} key={item.timestamp}>
        <Row>
          <Col xs="3">{moment(item.timestamp).format("HH:mma DD/MM/YYYY")}:</Col>
          <Col xs="9">
            {item.logArray.map(log => {
              return (
                "• " +
                Object.keys(log).map(key => {
                  return ` ${key}: ${log[key]}`;
                }) +
                " \n\n"
              );
            })}
          </Col>
        </Row>
      </Card>
    ));
  }

  render() {
    const { user, spinnerIsOn, integrationState, integrationSwitch, modalIsOpen, modalMessage, showLogs, service, logsButtonTitle } = this.state;
    const style = {
      padding: 15,
      textAlign: "left",
      whiteSpace: "pre-line"
    };
    return (
      <React.Fragment>
        <IntegrationModal toggleModal={this.handleToggleModal} isOpen={modalIsOpen} header="Automatic Clock Setting Error" message={modalMessage} />
        <h1>Automatic Clock Set Engine</h1>
        <br />
        {user.account_plan === 0 ? <UserPlanAlert user={user} color="info" title="Batch Clock Setting Engine" /> : null}
        <br />
        <Card>
          <CardBody>
            <div style={{ textAlign: "left", padding: "10px" }}>
              <Row style={{ paddingBottom: 20 }}>
                <Col sm="8">
                  <FormGroup>
                    <Label for="lockTitle">
                      <h6>Automatic Clock Setting Engine Toggle</h6>
                    </Label>
                    <OnOffSwitch
                      id="integrationSwitch"
                      checked={integrationSwitch}
                      label={"Toggle the automatic clock setting engine here"}
                      onChange={e => this.handleSwitchChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Col sm="1">{spinnerIsOn ? <Spinner color="primary" style={{ marginRight: "30px" }} /> : null}</Col>
                <Col sm="3" style={{ textAlign: "center" }}>
                  <IntegrationBadge bool={integrationState} />
                </Col>
              </Row>
              <p>
                This engine will automatically synchronize the clock for all <b>Admin Locks</b> for this account. It will not set the clock for any
                Manager locks. This feature can only be enabled with a paid plan.
              </p>
              <p>This tool will run every week on Sunday evening.</p>
              <Button onClick={this.hideShowLogs}>{logsButtonTitle}</Button>
              <br />
              <br />
              {showLogs ? (
                <div>
                  <p>
                    The following logs are captured every time the tool runs each week. This list will only track the logs that were not successful.
                  </p>
                  {this.renderLogs(service, style)}
                </div>
              ) : null}
            </div>
          </CardBody>
        </Card>
        <hr />
        <AccountFooter />
      </React.Fragment>
    );
  }
}

export default ClockEngine;
