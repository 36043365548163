import React from "react";
import { Alert } from "reactstrap";
import { apiDocs } from "../../../config.json";

const IntegrationAlert = () => {
  const [visible, setVisible] = React.useState(true);

  return (
    <Alert color="secondary" isOpen={visible} toggle={() => setVisible(false)}>
      Don't see your booking platform here? Build your own! Start integrating your platform today!{" "}
      <a href={apiDocs} target="_blank">
        Click Here
      </a>{" "}
      to view the docs. All information to get started is there.
    </Alert>
  );
};

export default IntegrationAlert;
