import React from "react";
import { Link } from "react-router-dom";
import {
    Collapse,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarToggler,
    Badge,
    Tooltip
} from "reactstrap";
import { upgradePlanLink, portalLink } from "../config.json";
import { navBarTitle } from "../config";

export default class TopNavBar extends React.Component {
    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = {
            collapsed: true,
            tooltip: false
        };
    }

    toggle() {
        this.setState({ toolTip: !this.state.toolTip });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        const { user, name } = this.props;
        return (
            <div>
                {/* <Navbar dark className="navbar-expand-lg bg-dark">
                    <p style={{color: "yellow"}}>Migration in process - We are currently in the middle of a migration and the console will be down for a short period. We expect this to take approximately 30-60mins. Sorry for any inconvenience.</p>
                </Navbar> */}
                <Navbar dark className="navbar-expand-lg bg-dark">
                    <NavbarBrand style={{ color: "#FFF" }}>{navBarTitle || "KAS Cloud Web Console"}</NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse isOpen={!this.state.collapsed} navbar>
                        <Nav className="ml-auto" navbar>
                            {!user && (
                                <NavItem>
                                    <NavLink href="/login">Login</NavLink>
                                </NavItem>
                            )}
                            {user && (
                                <React.Fragment>
                                    <NavItem>
                                        <NavLink tag={Link} to="/locks">
                                            Locks
                                    </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/gateways">
                                            Gateways
                                    </NavLink>
                                    </NavItem>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>
                                            {name ? name : "Account"}
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem tag={Link} to="/account">
                                                My Account
                                        </DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem header>Account Tools</DropdownItem>
                                            <DropdownItem tag={Link} to="/account/clockEngine" id="batchClock">
                                                Automatic Clock Set Engine
                                            </DropdownItem>
                                            <Tooltip placement="left" isOpen={this.state.toolTip} autohide={true} target="batchClock" toggle={this.toggle}>
                                                Run this tool to automatically set the clock for all of your locks once per week. Easy, set and forget feature. Paid plan
                                                required.
                                            </Tooltip>
                                            <DropdownItem divider />
                                            <DropdownItem header>Add Ons</DropdownItem>
                                            <DropdownItem tag={Link} to="/integrations">
                                                Integrations
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/account/apikey">
                                                Dynamic API Key
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/account/staic-api-key">
                                                Static API Key
                                            </DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem header>Manage Plans</DropdownItem>
                                            <DropdownItem href={upgradePlanLink} target="_blank">
                                                Sign Up
                                            </DropdownItem>
                                            <DropdownItem href={portalLink} target="_blank">
                                                Manage Billing
                                            </DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem tag={Link} to="/logout">
                                                Logout
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </React.Fragment>
                            )}
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}
