import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormText, Row, Col, Spinner } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

class UpdateNameModal extends Component {
  formatTimezone() {
    const { selectedLockTimeZone } = this.props;
    if (selectedLockTimeZone === "") return <Spinner type="grow" color="primary" />;
    return `Lock Time Zone: ${selectedLockTimeZone}`;
  }

  // state = {
  //   startDate: moment(),
  //   endDate: moment()
  // };

  // handleStartChange = date => {
  //   this.setState({
  //     startDate: date
  //   });
  // };

  render() {
    const {
      modalName,
      selectedLock,
      updateNameInputValue,
      toggleModal,
      onUpdateName,
      updateInputValue,
      onTimezoneChange,
      selectedLockTimeZone,
      timezoneNames
    } = this.props;

    if (modalName === true) {
      //modal is displaying
      if (selectedLockTimeZone === "") {
        this.props.getLockTimezone(selectedLock.l_factory_name);
      }
    }

    return (
      <Modal isOpen={modalName}>
        <ModalHeader>Update Lock Settings</ModalHeader>
        <ModalBody>
          <Form>
            Selected lock: {selectedLock.l_lock_title} - {selectedLock.l_factory_name}
            <FormGroup>
              <Label for="lockTitle">Lock Title</Label>
              <Row>
                <Col size="12">
                  <Input
                    type="text"
                    name="lockTitle"
                    id="lockTitle"
                    value={updateNameInputValue}
                    onChange={event => updateInputValue(event)}
                    placeholder="Enter a new lock title"
                  />
                </Col>
                <Col size="3">
                  <Button size="sm" color="primary" onClick={event => onUpdateName(event)}>
                    Update Lock Title
                  </Button>{" "}
                </Col>
              </Row>
              <FormText color="muted">
                This is the display name for the lock. Once set, it will be displayed on all devices. Only the Admin can set the lock name.
              </FormText>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <Label for="timezoneSelect">Time Zone:</Label>
              <Row>
                <Col size="12">
                  <Input type="select" name="select" value={selectedLockTimeZone} id="timezoneSelect" onChange={event => onTimezoneChange(event)}>
                    {timezoneNames.map(name => (
                      <option key={name}>{name}</option>
                    ))}
                  </Input>
                </Col>
                <Col size="3">{this.formatTimezone()}</Col>
              </Row>
              <FormText color="muted">
                This is the timezone of the physical lock. For example: if this lock is located in Sydney, enter the Sydney timezone here. This
                information is used when setting the clock.
              </FormText>
            </FormGroup>
          </Form>
          {/* <Form>
            <FormGroup>
              <Label for="freePassageSelect">Free Passage Setting:</Label>
              <Row>
                <Col size="12">
                  <DatePicker
                    // selected={this.state.startDate}
                    onChange={this.handleStartChange}
                    // showTimeSelect
                    timeFormat="HH:mm"
                    // dateFormat="MMMM d, yyyy h:mm aa"
                    timeCaption="Start Time"
                  />
                </Col>
                <Col size="3"></Col>
              </Row>
              <FormText color="muted">info</FormText>
            </FormGroup>
          </Form> */}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default UpdateNameModal;
