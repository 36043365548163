import React, { Component } from "react";
import Table from "./common/table";
import moment from "moment-timezone";

class AuditTable extends Component {
  columns = [
    {
      path: "event_type_label",
      label: `Event Type`
    },
    {
      path: "date_added",
      label: "Event Date",
      content: date => {
        return this.formatDate(date.date_added, date.date_added_timezone_name);
      }
    },
    {
      path: "date_added_timezone_name",
      label: "Timezone"
    },
    {
      path: "user_id",
      label: `User Id`
    },
    {
      path: "user_type_desc",
      label: `User Type`
    },
    {
      path: "user_name",
      label: `Name`
    },
    {
      path: "user_id_value",
      label: `Value`
    },
    {
      path: "battery",
      label: `Battery %`
    }
  ];

  formatDate(date, timezone) {
    //moment.locale("en");
    return moment.tz(date, timezone).format("D MMM YYYY h:mm a");
  }

  constructor() {
    super();
  }

  render() {
    const { events, onSort, sortColumn } = this.props;
    if (events.length === 0) return "No Events";
    return (
      <Table
        columns={this.columns}
        data={events}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default AuditTable;
