import React from "react";

const Like = props => {
  let classes = "fa fa-heart";
  if (!props.liked) classes += "-o";

  return (
    <i className={classes} />
    // <i
    //   onClick={props.onClick}
    //   style={{ cursor: "pointer" }}
    //   className={classes}
    //   aria-hidden="true"
    // />
  );
};

export default Like;
