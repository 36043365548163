import React from "react";
import { version } from "./../../config.json";
import moment from 'moment';

const AccountFooter = () => {
  return (
    <div>
      <p>Web Console Version: {version}</p>
      <p>Copyright © {moment().year()} KAS Keyless Access Security </p>
    </div>
  );
};

export default AccountFooter;
