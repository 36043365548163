import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Button, Row, Col } from "reactstrap";

class AdminLogin extends Component {
    state = {};
    render() {
        const {
            onUsernameChange,
            onPasswordChange,
            usernameValue,
            passwordvalue,
            onSubmit
        } = this.props;
        return (
            <Row>
                {/* <Col size="2"> </Col> */}
                <Col>
                    <Form>
                        <FormGroup>
                            <Label for="username">Username</Label>
                            <Input
                                type="text"
                                name="text"
                                id="text"
                                placeholder="Enter Username"
                                value={usernameValue}
                                onChange={event => onUsernameChange(event)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="examplePassword">Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="examplePassword"
                                placeholder="Enter password"
                                value={passwordvalue}
                                onChange={event => onPasswordChange(event)}
                            />
                        </FormGroup>
                        <Button onClick={event => onSubmit(event)}>Login</Button>
                    </Form>
                </Col>
                {/* <Col size="2"> </Col> */}
            </Row>
        );
    }
}

export default AdminLogin;
