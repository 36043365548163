import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import AuditTable from "./auditTable";
import { auditLabel } from "../config.json";
import InfoModal from "./common/infoModal";
import Moment from "moment";
import lockService from "../services/lockService";
import LoadingTableSpinner from "./common/loadingTableSpinner";
import { Link } from "react-router-dom";
import _ from "lodash";

class Audit extends Component {
  state = {
    events: [{}],
    serverFlag: 0,
    eventsCount: 0,
    searchQuery: "",
    sortColumn: { path: "date_added", order: "desc" }
  };

  componentDidMount() {
    const factoryName = this.props.computedMatch.params.factoryName;
    this.loadAuditData(factoryName);
  }

  async loadAuditData(factoryName) {
    const resp = await lockService.getAuditRecords(factoryName);
    const lockTitle = resp.data.l_lock_title;
    
    if (resp.data.ack === 2)
      return this.setState({
        eventsCount: 0,
        factoryName,
        lockTitle,
        serverFlag: 1
      });
    this.setState({
      events: resp.data.events.events,
      eventsCount: resp.data.events.events.length,
      factoryName,
      lockTitle: resp.data.events.l_lock_title,
      serverFlag: 1
    });
  }

  formatPage() {
    if (this.state.serverFlag === 0) return <LoadingTableSpinner />;
    const count = this.state.eventsCount;
    if (count === 0) return `No ${auditLabel}s for this lock.`;
    const { events, onSort, sortColumn } = this.state;
    const filteredEvents = this.sortAndFilterEvents(events);

    return (
      <AuditTable
        events={filteredEvents}
        sortColumn={sortColumn}
        onSort={this.handleSort}
      />
    );
  }

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  sortAndFilterEvents() {
    const { searchQuery, events, sortColumn } = this.state;
    let filtered = events;
    if (searchQuery)
      filtered = events.filter(u =>
        u.date_added.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sortedAndFiltered = _.orderBy(
      filtered,
      [sortColumn.path],
      [sortColumn.order]
    );

    return sortedAndFiltered;
  }

  render() {
    const style = {
      padding: 20
    };
    //const factoryName = this.props.computedMatch.params.factoryName;
    const { lockTitle, factoryName } = this.state;

    return (
      <React.Fragment>
        <Container>
          <Row>
            <h2>
              <Link to="/locks">Locks</Link> > {auditLabel}s: {lockTitle} -{" "}
              {factoryName}
            </h2>
          </Row>
          <Row style={style}>
            <br />
          </Row>
          <Row>{this.formatPage()}</Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Audit;
