import http from "./httpService";
import moment from 'moment';
import jwtDecode from "jwt-decode";
import toast from "react-toastify";
import { apiUrlProd, apiUrlDev } from "./../config";

let api = "";
if (process.env.NODE_ENV === "production") {
  api = apiUrlProd;
} else if (process.env.NODE_ENV === "development") {
  api = apiUrlDev;
}
const baseURL = api;

const apiEndPoint = baseURL + "/login";

http.setJwt(getJwt());

const tokenKey = "token";

export async function login(email, password) {
  try {
    const response = await http.post(apiEndPoint, { email, password });
    const jwt = response.headers["x-auth-token"];
    localStorage.setItem(tokenKey, jwt);
    setCookie();
  } catch (ex) {
    toast.warn("Login Failed. Please try again");
    console.log(ex);
  }
}

export function loginWithJwt(jwt, hoursExpire) {
  localStorage.setItem(tokenKey, jwt);
  setCookie(hoursExpire);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

function setCookie(hours = 720) { // 30 days
  const expire = moment().add(hours, 'hours').format("YYYY-MM-DD HH:mm");
  document.cookie = `ksession=${expire}`;
}

function getCookie() {
  const cookies = document.cookie;
  if (!cookies) return null;
  
  const cookieValue = cookies
    .split('; ')
    .find(row => row.startsWith('ksession'));

  const expireTime = moment(cookieValue, "YYYY-MM-DD HH:mm");
  if (expireTime < moment()) return null;
  return cookieValue;
}

export function getCurrentUser() {

  if (!getCookie()) {
    return null;
  }

  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt
};
