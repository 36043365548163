import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const IntegrationModal = ({ isOpen, header, message, toggleModal }) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{header}</ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => toggleModal()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default IntegrationModal;
