import http from "./httpService";
import auth from "./authService";
import { apiUrlProd, apiUrlDev } from "./../config";

let api = "";
if (process.env.NODE_ENV === "production") {
  api = apiUrlProd;
} else if (process.env.NODE_ENV === "development") {
  api = apiUrlDev;
}
const baseURL = api;

const apiEndpoint = baseURL + "/integration";

http.setJwt(auth.getJwt());

export async function getIntegrationAccount() {
  const resp = await http.get(apiEndpoint);
  return resp.data;
}

export async function putIntegrationAccount(body) {
  const resp = await http.put(apiEndpoint, body);
  return resp.data;
}

export async function putIntegrationAccountClockEngine(body) {
  const resp = await http.put(apiEndpoint + "/clockEngine", body);
  return resp.data;
}

export async function getIntegrationAccountLogs() {
  const resp = await http.get(apiEndpoint);
  return resp.data;
}

export default {
  getIntegrationAccount,
  putIntegrationAccount,
  getIntegrationAccountLogs,
  putIntegrationAccountClockEngine
};
