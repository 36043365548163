import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col, Form, Button, Spinner } from "reactstrap";
import Joi from "joi-browser";
import CommonForm from "./common/commonForm";
import authService from "../services/authService";
import { toast } from "react-toastify";
import { upgradePlanLink } from "../config.json";

class LoginForm extends CommonForm {
  state = {
    data: { email: "", password: "" },
    errors: {},
    spinnerIsOn: false
  };

  schema = {
    email: Joi.string()
      .required()
      .email()
      .label("Email"),
    password: Joi.string()
      .required()
      .min(3)
      .label("Password")
  };

  doSubmit = async () => {
    this.setState({ spinnerIsOn: true });
    try {
      const { data } = this.state;
      await authService.login(data.email, data.password);
      const { state } = this.props.location;
      window.location = state ? state.from.pathname : "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
      toast.warn("Error logging in");
    }
    this.setState({ spinnerIsOn: false });
  };

  render() {
    if (authService.getCurrentUser()) return <Redirect to="/" />;
    return (
      <React.Fragment>
        <h1>User Login</h1>
        <Row>
          <Col sm="2" />
          <Col sm="8">
            <Form onSubmit={this.handleSubmit}>
              {this.renderInput("email", "Email", "email", "Enter your account email (Same as on the Mobile App)")}
              {this.renderInput("password", "Password", "password", "Enter your account password")}
              <Row>
                <Col>
                  {this.renderButton("Login")} {this.state.spinnerIsOn ? <Spinner colour="primary" /> : null}
                </Col>
              </Row>
            </Form>
          </Col>
          <Col sm="2" />
        </Row>
        <hr />
        <Row>
          <h5>Want Full Access To The Web Console?</h5>
        </Row>
        <Row>
          <p>Sign up is quick and easy. All console features and integrations for supported PMS systems will be enabled.</p>
        </Row>
        <Row>
          <Button color="primary" href={upgradePlanLink} target="_blank">
            Sign Up Now
          </Button>
        </Row>
      </React.Fragment>
    );
  }
}

export default LoginForm;
