import React, { Component } from "react";
import { Tooltip } from "reactstrap";

class Clock extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  render() {
    let classes = "fa fa-clock-o";
    let factoryName = this.props.tooltipId;
    let lockName = this.props.lockName;
    let id = "clock-icon" + factoryName;
    return (
      <i
        className={classes}
        style={{ cursor: "pointer" }}
        onClick={this.props.onClick}
        id={id}
      >
        <Tooltip
          placement="top"
          isOpen={this.state.tooltipOpen}
          autohide={false}
          target={id}
          toggle={this.toggle}
        >
          Set Clock for: {lockName}
        </Tooltip>
      </i>
    );
  }
}

export default Clock;
