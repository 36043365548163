import React from "react";
import { Redirect } from "react-router-dom";
import authService from "../../services/authService";

const SuperAdminProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  const user = authService.getCurrentUser();
  if (user.is_super_admin) {
    return <Component {...rest} />;
  }
  return <Redirect to="/login" />;
};

export default SuperAdminProtectedRoute;
