import React, { Component } from "react";
import { Row, Col, CardBody, Card, CardHeader, CardFooter } from "reactstrap";
import authService from "../../services/authService";
import adminService from "../../services/adminService";
import moment from "moment";
import { apiDocs, freeTierLabel, paidTierLabel, upgradePlanLink } from "./../../config.json";
import AccountFooter from "../common/accountFooter";

import UserPlanAlert from "../integration/common/UserPlanAlert";

// import "./style.css";

class IntegrationMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {}
    };
  }

  componentDidMount() {
    const user = authService.getCurrentUser();
    this.getUserDetails(user._id);
  }

  async getUserDetails(user_id) {
    const resp = await adminService.getUser(user_id);

    this.setState({ user: resp.data });
  }

  render() {
    const { user } = this.state;
    const body = `
    { 
      "email": "ACCOUNT_EMAIL_HERE",
      "password": "ACCOUNT_PASSWORD_HERE"
    }
    `;
    const respBody = `<API_KEY>`;
    return (
      <React.Fragment>
        <h1>Dynamic API Key</h1>
        <br />
        {user.account_plan === 0 ? <UserPlanAlert user={user} color="info" title="API Keys" /> : null}
        <br />
        <Card>
          <CardBody>
            <div style={{ textAlign: "left" }}>
              <p>
                The API Key gives you the ability to conduct lock commands from another system. If you are a developer and want to build your system
                into the KAS system this is the place to start. If you are not a developer and want to integrate the KAS system into another system
                check out the <a href="/integrations">Integrations</a> page for pre-built plugins. Use the following endpoint to retrieve the API Key.
              </p>
              <h3>API Key Endpoint</h3>
              <h5>Request</h5>
              <p>Endpoint:</p>
              <pre>https://cloud.kas.com.au/api/users/apikey</pre>
              <p>Body:</p>
              <pre>{body}</pre>
              <p>Note: request is 'application/json'</p>
              <h5>Response</h5>
              <p>
                Status: <code>200</code>
              </p>
              <p>Body:</p>
              <pre>{respBody}</pre>
              <p>Note: response is 'text/plain'</p>
              <h3>API Key Expiry</h3>
              <p>The API Key has a 24h expiry. So you will need to refresh this token every 24hours.</p>
              <p>
                Invalid tokens will return <code>HTTP 401</code> with a body <code>Invalid Token.</code>{" "}
              </p>
              <h3>Documentation:</h3>
              For working examples please see documention:
              <h5>
                <a href={apiDocs}>Go to Docs</a>
              </h5>
              <br />
              <h3>Can't see what you want?</h3>
              <p>First step is to get started with the basics like, Remote Unlock.</p>
              <p>When you're ready contact us and we can open up more endpoints so it works best with your system.</p>
            </div>
          </CardBody>
        </Card>
        <hr />
        <AccountFooter />
      </React.Fragment>
    );
  }
}

export default IntegrationMain;
